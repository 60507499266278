import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components';
import { useJobDetailStatus } from 'src/helpers/hooks';
import CheckInFlow from '../CheckIn/CheckInFlow';
import classes from './Actions.module.scss';

interface Props {
  className?: string;
  onReject: () => void;
  onAccept: () => void;
  isAccepted?: boolean;
  onAcceptSubmit: (fn?: any) => void;
  onAcceptBack: () => void;
}

const Actions = ({
  className,
  onReject,
  onAccept,
  isAccepted,
  onAcceptSubmit,
  onAcceptBack,
}: Props) => {
  const { canRejectAccept, canCheckIn } = useJobDetailStatus();
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <div className={cn(classes.wrapper, className)}>
      {canCheckIn ? (
        <CheckInFlow onSubmit={onAcceptSubmit} onBack={onAcceptBack} />
      ) : canRejectAccept ? (
        <div className={classes.buttons}>
          {!isAccepted && (
            <Button type="default" size="large" onClick={onReject}>
              {t('buttons.reject', { ns: 'common' })}
            </Button>
          )}
          <Button type="primary" size="large" onClick={onAccept}>
            {t('buttons.accept', { ns: 'common' })}
          </Button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Actions;
