import cn from 'classnames';
import classes from './Timeline.module.scss';

const Timeline = ({ className = '' }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <span>15:46 21 Sep, 2022</span>
    </div>
  );
};

export default Timeline;
