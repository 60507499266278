import { Spin } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router';

export default function VerifyAuth() {
  // dispatch(actAuthenticateUserSuccess());
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const state = searchParams.get('state');
  if (state) {
    const config = JSON.parse(window.atob(state));
    if (config.token) {
      dispatch({
        type: 'VERIFIED_AUTH',
        payload: {
          token: config.token,
          isActivated: true,
          locked: false,
        },
      });
      return <Navigate to="/"></Navigate>;
    }
  }
  return <Spin spinning />;
}
