import cn from 'classnames';
import { ReactComponent as CheckUnCheck } from 'src/assets/icons/CheckUnCheck.svg';
import classes from './CheckUnCheckSvg.module.scss';

const CheckUnCheckSvg = ({ active = false }) => {
  return (
    <CheckUnCheck
      className={cn(classes.wrapper, {
        [classes.active]: active,
      })}
    />
  );
};

export default CheckUnCheckSvg;
