import { Button as AntdButton, ButtonProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import classes from './Button.module.scss';

type Props = ButtonProps & {
  className?: string;
  fullWidth?: boolean;
  color?: string;
  textWeight?: string;
};

const Button = ({
  className,
  fullWidth,
  color,
  textWeight,
  ...other
}: Props) => {
  return (
    <AntdButton
      htmlType="button"
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.fullWidth]: fullWidth,
          [classes.hasIcon]: !!other.icon,
        },
        `ant-btn-color-${color}`,
        classes[`textWeight_${textWeight}`],
        className
      )}
    />
  );
};

export default Button;
