import { createTypes } from './createTypes';

export default createTypes(
  'GET_JOBS',
  'GET_UPCOMING_JOBS',
  'GET_INPROGRESS_JOBS',
  'GET_ENDED_JOBS',
  'GET_DATES_HAS_JOBS',
  'SYNC_SET_SELECTED_DATE',
  'SYNC_JOB_FILTERS'
);
