import cn from 'classnames';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PendingSvg } from 'src/assets/icons/Pending.svg';
import {
  PARSED_CUSTOM_FIELDS,
  SECTION_1_PACKED_FIELD_NAME,
  SECTION_1_PRODUCED_FIELD_NAME,
  SECTION_3_NAME,
  TYPE_NAME_MAPPING,
  UPLOAD_TYPES,
} from 'src/helpers/constants';
import {
  Barcode,
  CalPercent,
} from 'src/pages/JobRequests/JobDetail/SubContent/SubInspection/components/Add1Field/Section5Information';
import { calculateValue } from 'src/pages/JobRequests/JobDetail/SubContent/SubInspection/components/Add1Field/utils';
import { getListFieldName } from 'src/pages/JobRequests/JobDetail/utils';
import { translateFreeText } from 'src/utils/languagues';
import { capitalizeWholeString } from 'src/utils/text';
import classes from './SectionValue.module.scss';

const getSection1TextPercentDisplay = (field, fields) => {
  const fieldPercentName =
    field.originalName === SECTION_1_PRODUCED_FIELD_NAME
      ? 'producedPercentage'
      : 'packedPercentage';
  const fieldLimitName =
    field.originalName === SECTION_1_PRODUCED_FIELD_NAME
      ? 'requestProduced'
      : 'requestPacked';
  const percentValue = find(fields, { originalName: fieldPercentName })?.value;
  const limitValue = find(fields, {
    originalName: fieldLimitName,
  })?.defaultValue;
  return (
    <>
      <span
        className={cn(classes.color, {
          [classes.red]: parseInt(percentValue, 10) < parseInt(limitValue, 10),
          [classes.green]:
            parseInt(percentValue, 10) >= parseInt(limitValue, 10),
        })}
      >
        {percentValue}%
      </span>{' '}
      / {field.value}
    </>
  );
};
const getParseCustomFieldValue = (field, values) => {
  const { sectionName } = getListFieldName(field.name);
  if (sectionName === SECTION_3_NAME) {
    const tFieldValue = values[field.name];
    return tFieldValue.quantity || 'N/A';
  }
  return '';
};

const ResultEmpty = () => {
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <span className={cn(classes.resultDisplay, classes.resultEmpty)}>
      <PendingSvg /> {t('text.Empty', { ns: 'common' })}
    </span>
  );
};
const getValueOfSection3TagField = (fields) => {
  let sumVal = 0;
  fields.map((field) => {
    const { nameIndex } = getListFieldName(field.name);
    const tItems = field.items[nameIndex] || [];
    const tQuantity = find(tItems, { name: 'quantity' })?.value;
    const intQuantity = tQuantity ? parseInt(tQuantity) : 0;
    sumVal = sumVal + intQuantity;
  });
  return sumVal;
};

const getNormalFieldValue = (field, fields, t) => {
  if (field.originalType === 'RADIO') {
    return <ResultEmpty />;
  } else if (field.originalName === 'numberOfDefect') {
    const criticalFields = fields.filter((f) => {
      const { nameIndex } = getListFieldName(f.name);
      const classificationValue = find(f.items?.[nameIndex] || [], {
        name: 'defectClassification',
      })?.value;
      return (
        f.items?.[nameIndex]?.length > 0 &&
        classificationValue &&
        classificationValue === 'critical'
      );
    });
    const criticalVal = getValueOfSection3TagField(criticalFields);
    const majorFields = fields.filter((f) => {
      const { nameIndex } = getListFieldName(f.name);
      const classificationValue = find(f.items?.[nameIndex] || [], {
        name: 'defectClassification',
      })?.value;
      return (
        f.items?.[nameIndex]?.length > 0 &&
        classificationValue &&
        classificationValue === 'major'
      );
    });
    const majorVal = getValueOfSection3TagField(majorFields);
    const minorFields = fields.filter((f) => {
      const { nameIndex } = getListFieldName(f.name);
      const classificationValue = find(f.items?.[nameIndex] || [], {
        name: 'defectClassification',
      })?.value;
      return (
        f.items?.[nameIndex]?.length > 0 &&
        classificationValue &&
        classificationValue === 'minor'
      );
    });
    const minorVal = getValueOfSection3TagField(minorFields);
    return (
      <span>
        <span>
          {criticalVal} {t('text.critical', { ns: 'common' })}
        </span>
        <br />
        <span>
          {majorVal} {t('text.major', { ns: 'common' })}
        </span>
        <br />
        <span>
          {minorVal} {t('text.minor', { ns: 'common' })}
        </span>
      </span>
    );
  }
  return typeof field.value !== 'object' && field.value ? field.value : 'N/A';
};
export const getDisplayFieldValue = (
  field,
  resultIconMapping,
  fields,
  values,
  t
) => {
  if (
    UPLOAD_TYPES.includes(field.originalType ? field.originalType : field.type)
  ) {
    const fileLength = field.value ? field.value.length : 0;
    return `${fileLength} ${translateFreeText(
      `File${fileLength > 1 || fileLength === 0 ? 's' : ''}`,
      t
    )}`;
  } else if (PARSED_CUSTOM_FIELDS.includes(field.type)) {
    return getParseCustomFieldValue(field, values);
  }
  const valueIcon = resultIconMapping[field.value];
  return valueIcon ? (
    <span className={classes.resultDisplay}>
      {valueIcon}{' '}
      {translateFreeText(
        capitalizeWholeString(field.value.replace('_', ' ')),
        t
      )}
    </span>
  ) : [SECTION_1_PRODUCED_FIELD_NAME, SECTION_1_PACKED_FIELD_NAME].includes(
      field.originalName
    ) ? (
    getSection1TextPercentDisplay(field, fields)
  ) : (
    getNormalFieldValue(field, fields, t)
  );
};
export const getDisplayFieldLabel = (field, hideRequire = false, t) => {
  const cleanLabel = (field.label || '')
    .replace('(L)', '')
    .replace('(W)', '')
    .replace('(H)', '');
  return `${translateFreeText(cleanLabel, t)}${
    field.tag
      ? ` (${field.tag})`
      : field.optional === true || !!hideRequire
      ? ''
      : ` (${t('form.required', { ns: 'common' })})`
  }`;
};
export const getSection5Value = (
  field,
  parentField,
  shipppingInfos,
  resultIconMapping,
  t
) => {
  const tType = TYPE_NAME_MAPPING[parentField.originalName];
  const tShippingInfo = find(shipppingInfos, { type: tType }) || {};
  let returnVal = field.value as any;
  const fileLength = returnVal?.length;
  const calLength = calculateValue(
    field.value,
    tShippingInfo.length,
    tShippingInfo.lengthUpperTolerance,
    tShippingInfo.lengthLowerTolerance
  );
  const calWidth = calculateValue(
    field.value,
    tShippingInfo.width,
    tShippingInfo.widthUpperTolerance,
    tShippingInfo.widthLowerTolerance
  );
  const calHeight = calculateValue(
    field.value,
    tShippingInfo.height,
    tShippingInfo.heightUpperTolerance,
    tShippingInfo.heightLowerTolerance
  );
  const calNetWeight = calculateValue(
    field.value,
    tShippingInfo.netWeight,
    tShippingInfo.netWeightUpperTolerance,
    tShippingInfo.netWeightLowerTolerance
  );
  const calGrossWeight = calculateValue(
    field.value,
    tShippingInfo.grossWeight,
    tShippingInfo.grossWeightUpperTolerance,
    tShippingInfo.grossWeightLowerTolerance
  );
  switch (field.name) {
    case 'length':
      returnVal = (
        <CalPercent
          data={calLength}
          unit={translateFreeText(tShippingInfo?.lengthUnit, t)}
        />
      );
      break;
    case 'width':
      returnVal = (
        <CalPercent
          data={calWidth}
          unit={translateFreeText(tShippingInfo?.widthUnit, t)}
        />
      );
      break;
    case 'height':
      returnVal = (
        <CalPercent
          data={calHeight}
          unit={translateFreeText(tShippingInfo?.heightUnit, t)}
        />
      );
      break;
    case 'netWeight':
      returnVal = (
        <CalPercent
          data={calNetWeight}
          unit={translateFreeText(tShippingInfo?.netWeightUnit, t)}
        />
      );
      break;
    case 'grossWeight':
      returnVal = (
        <CalPercent
          data={calGrossWeight}
          unit={translateFreeText(tShippingInfo?.grossWeightUnit, t)}
        />
      );
      break;
    case 'barcode':
      returnVal = (
        <Barcode
          barcode={field.value}
          actualBarcode={translateFreeText(tShippingInfo?.barcode, t)}
        />
      );
      break;
    case 'referenceMedia':
      returnVal = `${fileLength} ${translateFreeText(
        `File${fileLength > 1 || fileLength === 0 ? 's' : ''}`,
        t
      )}`;
      break;

    default:
      break;
  }
  if (field.type === 'RADIO') {
    const valueIcon = resultIconMapping[field.value];
    if (!valueIcon) {
      return <ResultEmpty />;
    }
    return (
      <span className={classes.resultDisplay}>
        {valueIcon}{' '}
        {translateFreeText(
          capitalizeWholeString(field.value.replace('_', ' ')),
          t
        )}
      </span>
    );
  }
  return returnVal;
};
