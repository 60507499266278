import cn from 'classnames';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { Spin } from 'src/components';
import classes from './Box.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  flex?: boolean;
  direction?: 'column' | 'row';
  onClick?: () => void;
  halfRadius?: 'top' | 'right' | 'bottom' | 'left';
  onMore?: () => void;
  moreRevert?: boolean;
  loading?: boolean;
}
const Box = ({
  className,
  children,
  flex,
  direction,
  onClick,
  halfRadius,
  onMore,
  moreRevert,
  loading,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.flex]: flex,
          [classes.pointer]: !!onClick,
          [classes[`${halfRadius}HalfRadius`]]: !!halfRadius,
          [classes.hasMore]: !!onMore,
          [classes.moreRevert]: moreRevert,
          [classes.hasLoading]: !!loading,
        },
        classes[direction],
        className
      )}
      onClick={onClick}
    >
      <>
        {loading && (
          <div className={classes.loading}>
            <Spin circle />
          </div>
        )}
        {onMore ? (
          <>
            <div>{children}</div>
            <span
              onClick={onMore}
              className={cn(classes.moreIcon, 'i-block-more-icon')}
            >
              <ArrowRightSvg />
            </span>
          </>
        ) : (
          children
        )}
      </>
    </div>
  );
};

export default Box;
