import cn from 'classnames';
import { Box } from 'src/components';
import classes from './Document.module.scss';

interface Props {
  className?: string;
  icon: React.ReactNode;
  title: string;
  des: string;
  onMore?: () => void;
  moreRevert?: boolean;
  itemNo?: string | number;
  disabled?: boolean;
  loading?: boolean;
}

const Document = ({
  className,
  icon,
  title,
  des,
  onMore,
  moreRevert,
  itemNo,
  disabled,
  loading,
}: Props) => {
  return (
    <Box
      className={cn(classes.wrapper, className)}
      onMore={disabled ? undefined : onMore}
      onClick={disabled ? undefined : onMore}
      flex
      moreRevert={moreRevert}
      loading={loading}
    >
      <div className={classes.inner}>
        {icon}
        <div className={classes.information}>
          <h3>{title}</h3>
          {/* item no */}
          {!!itemNo && <span>{itemNo}</span>}
          <span>{des}</span>
        </div>
      </div>
    </Box>
  );
};

export default Document;
