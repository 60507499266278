import moment from 'moment';

export const getMomentDate = (val = null) => {
  return val ? moment(val) : moment();
};
export const getCheckedInTime = (val?: string, isFull?: boolean) => {
  return (val ? moment(val) : moment()).format(
    isFull ? 'DD MMM YYYY HH:mm' : 'HH:mm'
  );
};
