// import get from 'lodash/get';
// import isArray from 'lodash/isArray';
import Compressor from 'compressorjs';
import { apiUploadDocumentList, apiUploadFiles } from 'src/api';

const useUploadFiles = () => {
  const compressImageFile = async (file: File): Promise<File | Blob> => {
    return new Promise((resolve) => {
      new Compressor(file, {
        quality: 0.6,
        maxWidth: 1024,
        maxHeight: 1024,
        convertSize: 1024 * 1024,
        convertTypes: ['image/jpeg', 'image/png', 'image/webp'],
        success: (compressedFile) => {
          resolve(compressedFile);
        },
      });
    });
  };
  const upload = async (files: any[], path: string | number, form?: any) => {
    try {
      const formData = new FormData();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const values = form ? form.getFieldsValue() : {};
      const resizeFiles = [];
      for (const f of files) {
        if ((f?.file?.type || '').includes('image')) {
          const cF = (await compressImageFile(f?.file?.originFileObj)) as any;
          formData.append('file[]', cF, cF?.name);
          resizeFiles.push(cF);
        } else {
          formData.append('file[]', f?.file?.originFileObj, f?.file?.name);
          resizeFiles.push(f?.file?.originFileObj);
        }
      }
      const uploadRes =
        files.length > 0 ? await apiUploadFiles(formData) : ({} as any);
      const uploadedFiles = uploadRes?.data?.data || [];
      const params: any = {
        documentLists: uploadedFiles.map((uploaded, index) => {
          const tF = resizeFiles[index];
          return {
            fileSize: tF?.size,
            filePath: path,
            fileName: uploaded.name.split('/')[1],
            mineType: uploaded.mineType,
          };
        }),
      };
      if (params.documentLists.length > 0) {
        const documentList = await apiUploadDocumentList(params);
        const returnDocumentListData = documentList?.data?.data || [];
        // if (files?.length) {
        //   returnDocumentListData.map((doc, index) => {
        //     const tFile = files[index];
        //     const tValue = values[tFile.name] || [];
        //     const tValueMap = isArray(tValue)
        //       ? tValue
        //       : get(tValue, 'referenceMedia') || [];
        //     form.setFieldValue(
        //       tFile.name,
        //       tValueMap.map((tFileValue) => {
        //         if (tFileValue.uid === tFile.file?.uid) {
        //           tFileValue.uploaded = true;
        //           tFileValue.docId = doc.id;
        //         }
        //         return tFileValue;
        //       })
        //     );
        //   });
        // }
        return returnDocumentListData;
      }
      return [];
    } catch (error) {
      throw new Error('Uploaded Failed');
    }
  };

  const uploadFilesFn = async (files: any[], path: string, form?: any) => {
    try {
      const documentList = await upload(files, path, form);
      return documentList;
    } catch (error) {
      console.error('uploadFile', error);
    }
  };

  return {
    uploadFilesFn,
  };
};

export default useUploadFiles;
