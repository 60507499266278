import cn from 'classnames';
import { urlify } from 'src/utils/text';
import classes from './SubRemark.module.scss';

interface Props {
  className?: string;
  remark: string;
}

const SubRemark = ({ className, remark }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div
        className={classes.content}
        dangerouslySetInnerHTML={{
          __html: `<div class="i-remark-content">${urlify(remark)}</div>`,
        }}
      ></div>
    </div>
  );
};

export default SubRemark;
