import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckOutSvg } from 'src/assets/icons/CheckOut.svg';
import { ReactComponent as HomeSvg } from 'src/assets/icons/Home.svg';
import { Button, Modal } from 'src/components';
import classes from './CheckIn.module.scss';

interface Props {
  isCheck?: boolean;
  isConfirm?: boolean;
  setIsConfirm?: (val: boolean) => void;
  setIsCheck?: (val: boolean) => void;
  onSubmit?: () => void;
  onBack?: () => void;
  text?: string | React.ReactNode;
  icon?: React.ReactNode;
  submitButtonText?: string;
  backButtonText?: string;
  children?: React.ReactNode;
  onClose?: () => void;
  submitLoading?: boolean;
}

const ActionModal = ({
  isCheck,
  isConfirm,
  setIsConfirm,
  setIsCheck,
  onSubmit,
  text,
  backButtonText,
  submitButtonText,
  onBack,
  children,
  onClose,
  icon,
  submitLoading,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <Modal
      open={isCheck || isConfirm}
      warning
      onClose={onClose ? onClose : () => {}}
      closable={false}
      maskClosable={false}
    >
      {children ? (
        children
      ) : (
        <>
          <div className={classes.checkoutContent}>
            {icon ? icon : isConfirm ? <HomeSvg /> : <CheckOutSvg />}
            <span>{text}</span>
          </div>
          <div>
            {isConfirm && (
              <Button
                type="default"
                size="large"
                onClick={() => {
                  if (onBack) onBack();
                  setIsConfirm(false);
                  setIsCheck(false);
                }}
                color="white"
              >
                {backButtonText || t('buttons.back', { ns: 'common' })}
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (isConfirm) {
                  setIsConfirm(false);
                  setIsCheck(true);
                } else if (onSubmit) onSubmit();
              }}
              fullWidth={!isConfirm || isCheck}
              loading={submitLoading}
            >
              {isCheck ? t('buttons.okay', { ns: 'common' }) : submitButtonText}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ActionModal;
