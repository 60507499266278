import cn from 'classnames';
import MessageType from 'src/interfaces/Message';
import classes from './Message.module.scss';

type MessageCType = MessageType & {
  className?: string;
};

const Message = ({ className, isMe, text, attachments }: MessageCType) => {
  const hasAttachment = (attachments || []).length > 0;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isMe]: isMe,
        },
        className
      )}
    >
      {!!text && <span>{text}</span>}
      {hasAttachment && (
        <ul>
          {attachments.map((att, i) => {
            return <li key={i}>{att.name}</li>;
          })}
        </ul>
      )}
    </div>
  );
};

export default Message;
