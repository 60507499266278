import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { Box } from 'src/components';
import classes from './Notification.module.scss';

interface Props {
  className?: string;
}

const Notification = ({ className }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  return (
    <Box className={cn(classes.wrapper, className)} flex direction="column">
      <span className={classes.unreadMark}></span>
      <h1>{t('notification.title')}</h1>
      <p>
        New inspection job 100008786 is assigned to you, please review the job
        details and accpet the job.
      </p>
      <div>
        <span>02 Jan 2022 {t('text.at', { ns: 'common' })} 15:33</span>
        <span>{t('notification.jobDetails')}</span>
      </div>
      <span className={classes.arrow}>
        <ArrowRightSvg />
      </span>
    </Box>
  );
};

export default Notification;
