import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownloadSvg } from 'src/assets/icons/Download.svg';
import { ReactComponent as FileSvg } from 'src/assets/icons/File.svg';
import { Icon, Spin } from 'src/components';
import { downloadFile } from 'src/utils/file';
import classes from './SubDocument.module.scss';

interface Props {
  className?: string;
  documentList: any[];
  onDownloadAll: any;
  allLoading: boolean;
}

const SubDocument = ({
  className,
  documentList,
  onDownloadAll,
  allLoading,
}: Props) => {
  const { t, i18n } = useTranslation(['jobRequests', 'common']);
  const docLength = documentList.length;
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.actions}>
        <span>
          {`${docLength} ${t('text.file', {
            ns: 'common',
          })}${docLength > 1 && i18n.language === 'en' ? 's' : ''}`}
        </span>
        <span onClick={onDownloadAll} className={classes.downloadAll}>
          {allLoading && <Spin circle color="#005eff" />}
          {t('buttons.downloadAll', { ns: 'common' })}
        </span>
      </div>
      <div className={classes.content}>
        <ul>
          {documentList.map((document, index: number) => {
            return (
              <li key={index}>
                <FileSvg />
                <div>
                  <h4>{document.fileName}</h4>
                  {/* <span>2MB</span> */}
                </div>
                <Icon
                  size={20}
                  pointer
                  onClick={() => {
                    downloadFile(document.url, document.fileName);
                  }}
                >
                  <DownloadSvg />
                </Icon>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SubDocument;
