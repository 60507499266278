import cn from 'classnames';
import classes from './Icon.module.scss';

interface Props {
  children: React.ReactNode;
  className?: string;
  size: number;
  pointer?: boolean;
  notMargin?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

const Icon = ({
  children,
  className,
  size,
  pointer,
  notMargin,
  onClick,
  disabled,
}: Props) => {
  return (
    <span
      onClick={disabled ? null : onClick}
      className={cn(
        classes.wrapper,
        {
          [classes.notMargin]: notMargin,
        },
        className
      )}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        cursor: disabled ? 'not-allowed' : pointer ? 'pointer' : 'default',
      }}
    >
      {children}
    </span>
  );
};

export default Icon;
