import * as chatActions from 'src/store/actions/chat';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    messages: useReducerData('chat', 'messages.data', []),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...chatActions,
  });
};
