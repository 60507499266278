import cn from 'classnames';
import React from 'react';
import MessageType from 'src/interfaces/Message';
import Message from '../Message';
import Timeline from '../Timeline';
import classes from './Messages.module.scss';

interface Props {
  messages: MessageType[];
  className?: string;
}

const Messages = ({ className = '', messages }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {messages.map((message, i) => {
        return (
          <React.Fragment key={i}>
            <Message {...message} />
            {i % 3 === 0 && <Timeline />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Messages;
