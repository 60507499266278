import cn from 'classnames';
import React from 'react';
// import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { ReactComponent as ESignSvg } from 'src/assets/icons/ESign.svg';
import classes from './ESign.module.scss';

interface Props {
  className?: string;
  canEdit?: boolean;
  onEdit?: () => void;
  signedDate?: string;
}

const ESign = ({ className, canEdit, onEdit, signedDate }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <h3>E-signature</h3>
      <div className={classes.inner}>
        <ESignSvg />
        <div>
          <span>E-Signature</span>
          <span>
            {signedDate
              ? `Completed - Signature Date: ${signedDate}`
              : 'No Signature'}
          </span>
        </div>
        {canEdit ? (
          <span className={classes.edit} onClick={onEdit}>
            Edit
          </span>
        ) : (
          <span className={classes.arrowRightIcon}>
            {/* <ArrowRightSvg /> */}
          </span>
        )}
      </div>
    </div>
  );
};

export default ESign;
