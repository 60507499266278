import { useTranslation } from 'react-i18next';
import { Button, FormItem } from 'src/components';
import { Input, Radio } from 'src/components/FormFields';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { getFieldListRequired } from 'src/utils/field';
import MediaField from '../MediaField';
import classes from './Add1Field.module.scss';
import { Section5Information } from './Section5Information';

const Section5Form = ({
  form,
  thisShippingInfo,
  field,
  isSection5 = false,
}: {
  form: any;
  field: any;
  name: string;
  thisShippingInfo: any;
  isSection5?: boolean;
}) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const values = form.getFieldsValue();
  const thisFields = field.fields || [];
  return (
    <div className={classes.section5Form}>
      <FormSection
        title={t('text.Actual Dimensions', { ns: 'common' })}
        onBox
        required={
          getFieldListRequired(thisFields, 'length') ||
          getFieldListRequired(thisFields, 'width') ||
          getFieldListRequired(thisFields, 'height')
        }
      >
        <FormItem
          name={'section_5_length'}
          label={t('text.Length', { ns: 'common' })}
          rules={
            getFieldListRequired(thisFields, 'length') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_length'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
            suffix={thisShippingInfo?.lengthUnit}
          />
        </FormItem>
        <FormItem
          name={'section_5_width'}
          label={t('text.Width', { ns: 'common' })}
          rules={
            getFieldListRequired(thisFields, 'width') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_width'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
            suffix={thisShippingInfo?.widthUnit}
          />
        </FormItem>
        <FormItem
          name={'section_5_height'}
          label={t('text.Height', { ns: 'common' })}
          rules={
            getFieldListRequired(thisFields, 'height') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_height'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
            suffix={thisShippingInfo?.heightUnit}
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Quantity / Inner Carton', { ns: 'common' })}
        onBox
        hide={thisShippingInfo?.type !== 'inner_carton'}
        required={getFieldListRequired(thisFields, 'totalQuantity')}
      >
        <FormItem
          name={'section_5_qty'}
          label="Qty."
          rules={
            getFieldListRequired(thisFields, 'totalQuantity') &&
            isSection5 &&
            thisShippingInfo?.type == 'inner_carton'
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_qty'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Total Quantity / Outer Caton', { ns: 'common' })}
        onBox
        hide={thisShippingInfo?.type !== 'outer_carton'}
        required={getFieldListRequired(thisFields, 'outerQuantity')}
      >
        <FormItem
          name={'section_5_outer_qty'}
          label="Qty."
          rules={
            getFieldListRequired(thisFields, 'outerQuantity') &&
            isSection5 &&
            thisShippingInfo?.type === 'outer_carton'
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_outer_qty'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.No_of Inner / Outer Carton', { ns: 'common' })}
        onBox
        hide={thisShippingInfo?.type !== 'outer_carton'}
        required={getFieldListRequired(thisFields, 'outerCarton')}
      >
        <FormItem
          name={'section_5_outer_no_qty'}
          label="Qty."
          rules={
            getFieldListRequired(thisFields, 'outerCarton') &&
            isSection5 &&
            thisShippingInfo?.type === 'outer_carton'
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_outer_no_qty'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Actual Barcode', { ns: 'common' })}
        onBox
        required={getFieldListRequired(thisFields, 'actualBarcode')}
      >
        <FormItem
          name={'section_5_barcode'}
          label={t('text.Barcode Number', { ns: 'common' })}
          rules={
            getFieldListRequired(thisFields, 'actualBarcode') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_barcode'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Actual Net Weight', { ns: 'common' })}
        onBox
        required={getFieldListRequired(thisFields, 'netWeight')}
      >
        <FormItem
          name={'section_5_netWeight'}
          label={t('text.Weight', { ns: 'common' })}
          rules={
            getFieldListRequired(thisFields, 'netWeight') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_netWeight'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
            suffix={thisShippingInfo?.netWeightUnit}
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Actual Gross Weight', { ns: 'common' })}
        onBox
        required={getFieldListRequired(thisFields, 'grossWeight')}
      >
        <FormItem
          name={'section_5_grossWeight'}
          label={t('text.Weight', { ns: 'common' })}
          rules={
            getFieldListRequired(thisFields, 'grossWeight') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_grossWeight'}
            placeholder={t('form.placeholder.number', {
              ns: 'common',
            })}
            type={'text'}
            size="large"
            suffix={thisShippingInfo?.grossWeightUnit}
          />
        </FormItem>
      </FormSection>
      <Section5Information
        thisShippingInfo={thisShippingInfo}
        values={{
          length: values.section_5_length,
          width: values.section_5_width,
          height: values.section_5_height,
          netWeight: values.section_5_netWeight,
          grossWeight: values.section_5_grossWeight,
          actualBarcode: values.section_5_barcode,
        }}
        fields={thisFields}
      />
      <FormSection
        title={t('text.Section Result', { ns: 'common' })}
        required={getFieldListRequired(thisFields, 'result')}
        onBox
      >
        <FormItem
          name={'section_5_result'}
          rules={
            getFieldListRequired(thisFields, 'result') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Radio
            name={'section_5_result'}
            options={[
              {
                value: 'passed',
                label: t('text.Passed', { ns: 'common' }),
              },
              {
                value: 'failed',
                label: t('text.Failed', { ns: 'common' }),
              },
              {
                value: 'pending',
                label: t('text.Pending', { ns: 'common' }),
              },
            ]}
            fullWidth
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Section Remarks', { ns: 'common' })}
        required={getFieldListRequired(thisFields, 'remark')}
        onBox
      >
        <FormItem
          name={'section_5_remark'}
          rules={
            getFieldListRequired(thisFields, 'remark') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <Input
            name={'section_5_remark'}
            placeholder={t('text.Section Remarks', { ns: 'common' })}
            type={'textarea'}
            size="large"
          />
        </FormItem>
      </FormSection>
      <FormSection
        title={t('text.Reference Media', { ns: 'common' })}
        required={getFieldListRequired(thisFields, 'referenceMedia')}
        onBox
        des={
          <>
            <span>{t('form.placeholder.image1', { ns: 'common' })}</span>
            <span>{t('form.placeholder.image2', { ns: 'common' })}</span>
          </>
        }
      >
        <FormItem
          name={'section_5_referenceMedia'}
          rules={
            getFieldListRequired(thisFields, 'referenceMedia') && isSection5
              ? [
                  {
                    required: true,
                    message: t('form.validation.required', { ns: 'common' }),
                  },
                ]
              : []
          }
        >
          <MediaField name="section_5_referenceMedia" form={form} />
        </FormItem>
      </FormSection>
      <Button type="primary" fullWidth htmlType="submit" size="large">
        {t('buttons.done', { ns: 'common' })}
      </Button>
    </div>
  );
};
export default Section5Form;
