import { SORT_VALUES, STATUS_VALUES } from 'src/helpers/constants';
export const TABS = {
  UP_COMING: '1',
  IN_PROGRESS: '2',
  ENDED: '3',
};
export const FILTER_OPTIONS = {
  [TABS.UP_COMING]: [
    STATUS_VALUES.DEFAULT,
    STATUS_VALUES.WAITING_TO_ACCEPT,
    STATUS_VALUES.SCHEDULED,
  ],
  [TABS.IN_PROGRESS]: [],
  [TABS.ENDED]: [
    SORT_VALUES.ALL,
    SORT_VALUES.LAST_3_MONTHS,
    SORT_VALUES.LAST_6_MONTHS,
  ],
};
export const DEFAULT_FILTER = {
  [TABS.UP_COMING]: STATUS_VALUES.DEFAULT,
  [TABS.IN_PROGRESS]: '',
  [TABS.ENDED]: SORT_VALUES.ALL,
};
