import React from 'react';
import { ReactComponent as ICWLogoSvg } from 'src/assets/icons/ICWLogo.svg';
import classes from './Auth.module.scss';

interface Props {
  children: React.ReactNode;
}

const AuthLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <ICWLogoSvg />
      </div>
      <div className={classes.inner}>{children}</div>
    </div>
  );
};

export default AuthLayout;
