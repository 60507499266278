import * as jobActions from 'src/store/actions/job';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    upcomingJobsData: useReducerData('job', 'upcomingJobs.data', {}),
    inprogressJobsData: useReducerData('job', 'inprogressJobs.data', {}),
    endedJobsData: useReducerData('job', 'endedJobs.data', {}),
    jobFiltersStore: useReducerData('job', 'jobFilters', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...jobActions,
  });
};
