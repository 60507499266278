import { Tabs as AntdTabs, TabsProps } from 'antd';
import cn from 'classnames';
import classes from './Tabs.module.scss';

type Props = TabsProps & {
  className?: string;
};

const Tabs = ({ className, ...other }: Props) => {
  return <AntdTabs {...other} className={cn(classes.wrapper, className)} />;
};

export default Tabs;
