import { Checkbox as AntdCheckbox, CheckboxProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import classes from './Checkbox.module.scss';

type Props = CheckboxProps & {
  className?: string;
};

const Checkbox = ({ className, ...other }: Props) => {
  return <AntdCheckbox {...other} className={cn(classes.wrapper, className)} />;
};

export default Checkbox;
