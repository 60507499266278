import { Radio as AntdRadio, RadioProps, Space } from 'antd';
import cn from 'classnames';
import classes from './Radio.module.scss';

interface RadioOption {
  value: string | number;
  label: string;
}
type Props = RadioProps & {
  className?: string;
  options: RadioOption[];
  fullWidth?: boolean;
};

const Radio = ({
  className,
  value,
  onChange,
  name,
  options,
  fullWidth,
  ...other
}: Props) => {
  return (
    <AntdRadio.Group
      {...other}
      value={value}
      onChange={onChange}
      name={name}
      className={cn(
        classes.wrapper,
        {
          [classes.fullWidth]: fullWidth,
        },
        className
      )}
    >
      <Space direction="vertical" size="middle">
        {options.map((option: RadioOption, i: number) => {
          return (
            <AntdRadio value={option.value} key={i}>
              {option.label}
            </AntdRadio>
          );
        })}
      </Space>
    </AntdRadio.Group>
  );
};

export default Radio;
