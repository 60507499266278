import Container from 'src/pages/JobRequests/components/Container';
import Notification from 'src/pages/JobRequests/components/Notification';
import classes from './Notifications.module.scss';

const Notifications = () => {
  return (
    <Container className={classes.wrapper}>
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
      <Notification />
    </Container>
  );
};

export default Notifications;
