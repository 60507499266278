import { AlertProps, Alert as AntdAlert } from 'antd';
import cn from 'classnames';
import classes from './Alert.module.scss';

type Props = AlertProps & {
  className?: string;
};

const Alert = ({ className, ...other }: Props) => {
  return <AntdAlert {...other} className={cn(classes.wrapper, className)} />;
};

export default Alert;
