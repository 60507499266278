import { Reducer } from 'redux';
import { defaultArr, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/jobDetail';

const initialState = {
  jobDetail: {
    ...defaultObj,
  },
  poDetail: {
    ...defaultObj,
  },
  jobDocuments: {
    ...defaultArr,
  },
  rejectJob: {
    ...defaultObj,
  },
  acceptJob: {
    ...defaultObj,
  },
  checkInJob: {
    ...defaultObj,
  },
  checkOutJob: {
    ...defaultObj,
  },
  inspectionSubmit: {
    ...defaultObj,
  },
};

const JobDetailReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;
  const { updatedJobDetail, updatedJobDocuments, force } = payload || {};

  switch (type) {
    case Types.SYNC_UPDATE_JOB_DETAIL: {
      return {
        ...state,
        jobDetail: {
          ...state.jobDetail,
          data: force
            ? {
                ...(updatedJobDetail || {}),
              }
            : {
                ...state.jobDetail.data,
                ...(updatedJobDetail || {}),
              },
        },
      };
    }
    case Types.SYNC_UPDATE_JOB_DOCUMENTS: {
      return {
        ...state,
        jobDocuments: {
          ...state.updatedJobDocuments,
          data: {
            ...(updatedJobDocuments || []),
          },
        },
      };
    }
    case Types.GET_JOB_DETAIL:
      return handleData(state, action, {
        request: (prevState) => {
          return {
            ...prevState,
            jobDetail: {
              ...prevState.jobDetail,
              loading: !meta?.payload?.silent,
            },
          };
        },
        success: (prevState) => ({
          ...prevState,
          jobDetail: {
            loading: false,
            data: {
              ...(payload.data || {}),
              // checkoutTime: payload.data?.checkinTime,
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          jobDetail: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_PO_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          poDetail: {
            loading: true,
            data: {},
            error: '',
          },
        }),
        success: (prevState) => ({
          ...prevState,
          poDetail: {
            loading: false,
            data: {
              ...(payload.data || {}),
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          poDetail: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_JOB_DOCUMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          jobDocuments: {
            ...prevState.jobDocuments,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          jobDocuments: {
            loading: false,
            data: payload.data?.list || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          jobDocuments: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.REJECT_JOB:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          rejectJob: {
            ...prevState.rejectJob,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          rejectJob: {
            loading: false,
            data: payload.data || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          rejectJob: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.ACCEPT_JOB:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          acceptJob: {
            ...prevState.acceptJob,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          acceptJob: {
            loading: false,
            data: payload.data || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          acceptJob: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CHECK_IN_JOB:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          checkInJob: {
            ...prevState.checkInJob,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          checkInJob: {
            loading: false,
            data: payload.data || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          checkInJob: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CHECK_OUT_JOB:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          checkOutJob: {
            ...prevState.checkOutJob,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          checkOutJob: {
            loading: false,
            data: payload.data || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          checkOutJob: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.INSPECTION_SUBMIT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          inspectionSubmit: {
            ...prevState.inspectionSubmit,
            loading: !meta?.payload?.silent,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          inspectionSubmit: {
            loading: false,
            data: payload.data || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          inspectionSubmit: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default JobDetailReducer;
