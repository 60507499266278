import { Progress as AntdProgress, ProgressProps } from 'antd';
import cn from 'classnames';
import classes from './Progress.module.scss';

type Props = ProgressProps & {
  className?: string;
};

const Progress = ({ className, ...other }: Props) => {
  return <AntdProgress {...other} className={cn(classes.wrapper, className)} />;
};

export default Progress;
