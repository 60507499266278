import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UserSvg } from 'src/assets/icons/User.svg';
import { Box, JobStatus } from 'src/components';
// import TypeJob from 'src/interfaces/Job';
import { STATUS_VALUES } from 'src/helpers/constants';
import { getJobInfo } from 'src/utils/job';
import classes from './Job.module.scss';

interface Props {
  data: any;
  onClick?: () => void;
}

const Job = ({ data, onClick }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const {
    dateRangeForList,
    stateName,
    address,
    name,
    contactPerson,
    phone,
    status,
  } = getJobInfo(data, t);
  return (
    <Box
      className={cn(classes.wrapper, {
        [classes.active]: [
          STATUS_VALUES.WAITING_TO_ACCEPT,
          STATUS_VALUES.SCHEDULED,
          STATUS_VALUES.IN_PROGRESS,
        ].includes(status),
        [classes.red]: [STATUS_VALUES.REJECTED].includes(status),
      })}
      flex
      direction="column"
      onClick={onClick}
    >
      <div className={classes.jobHeader}>
        <h1>
          {dateRangeForList}
          {stateName ? `, ${stateName}` : ''}
        </h1>
        <p>{address}</p>
      </div>
      <div className={classes.jobContent}>
        <p>{name}</p>
        <p>
          <span className={classes.userIcon}>
            <UserSvg />
          </span>
          {contactPerson}
          {phone ? `, ${phone}` : ''}
        </p>
        <JobStatus status={status} />
      </div>
    </Box>
  );
};

export default Job;
