import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckSvg } from 'src/assets/icons/Check.svg';
import { SORT_TEXTS, STATUS_TEXTS } from 'src/helpers/constants';
import classes from './FilterPanel.module.scss';

interface Props {
  className?: string;
  filterList: string[];
  currentFilter: string;
  onFilter: (val: string) => () => void;
}
const FilterPanel = ({
  className,
  filterList,
  currentFilter,
  onFilter,
}: Props) => {
  const { t } = useTranslation('common');

  return (
    <div className={cn(classes.wrapper, className)}>
      <ul>
        {filterList.map((f, i) => {
          return (
            <li
              key={i}
              className={cn({
                [classes.active]: currentFilter === f,
              })}
              onClick={onFilter(f)}
            >
              <span>
                <CheckSvg />
              </span>
              {STATUS_TEXTS[f] || SORT_TEXTS[f] ? t(`statuses.${f}`) : f}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterPanel;
