import {
  BE_REQUEST_DATE_FORMAT,
  SORT_VALUES,
  STATUS_VALUES,
} from 'src/helpers/constants';
import { getMomentDate } from 'src/utils/date';
import { TABS } from './constants';

export const getJobFilter = (filter, activeTab) => {
  const returnObj = {} as any;
  if (
    activeTab === TABS.UP_COMING &&
    !!filter &&
    filter !== STATUS_VALUES.DEFAULT
  ) {
    returnObj.status = filter;
  }
  if (activeTab === TABS.UP_COMING) {
    returnObj.sortBy = 'inspectionDate:asc';
  }
  if (activeTab === TABS.ENDED && !!filter) {
    if ([SORT_VALUES.OLD_TO_NEW, SORT_VALUES.NEW_TO_OLD].includes(filter)) {
      returnObj.sortBy = `updatedAt:${
        filter === SORT_VALUES.OLD_TO_NEW ? 'desc' : 'asc'
      }`;
    } else if (
      [SORT_VALUES.LAST_3_MONTHS, SORT_VALUES.LAST_6_MONTHS].includes(filter)
    ) {
      const today = getMomentDate();
      returnObj.startDate = today
        .clone()
        .subtract(filter === SORT_VALUES.LAST_3_MONTHS ? 3 : 6, 'month')
        .format(BE_REQUEST_DATE_FORMAT);
      returnObj.endDate = today.clone().format(BE_REQUEST_DATE_FORMAT);
    }
  }
  return returnObj;
};
