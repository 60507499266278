export const WARNING_ACTIONS = {
  SWITCH_TO_UPLOAD: 'switch-to-upload',
  SWITCH_TO_PAD: 'switch-to-pad',
  DELETE_FILE: 'delete-file',
  CLOSE_MODAL: 'close-modal',
};
export const SIGNATURE_ACTIONS = {
  PAD: 'pad',
  UPLOAD: 'upload',
};
