import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from 'src/components';
import { Input } from 'src/components/FormFields';
import classes from './RejectForm.module.scss';

interface Props {
  className?: string;
  onSubmit: (reason: string, cb?: any) => void;
}

const RejectForm = ({ className, onSubmit }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [reason, setReason] = useState('');

  return (
    <div className={cn(classes.wrapper, className)}>
      <Box className={classes.inputWrapper}>
        <Input
          type="textarea"
          label={t('jobDetail.reject.inputLabel')}
          placeholder={t('jobDetail.reject.inputPlaceholder')}
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
        />
      </Box>
      <Button
        type="primary"
        onClick={() => {
          onSubmit(reason, () => {
            setReason('');
          });
        }}
        disabled={!reason}
        size="large"
        fullWidth
      >
        {t('buttons.confirmReject', { ns: 'common' })}
      </Button>
    </div>
  );
};

export default RejectForm;
