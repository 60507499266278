export const scrollChatContentToBottom = (offset = null) => {
  setTimeout(() => {
    const publicLayoutWrapper = document.getElementById(
      'i-main-layout-wrapper'
    );
    if (publicLayoutWrapper) {
      publicLayoutWrapper.scroll({
        top: offset || publicLayoutWrapper.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, 0);
};
