import React from 'react';
import { Button } from 'src/components';
import { useActions } from './selectorData';

const Dashboard = () => {
  const { logOut } = useActions();
  return (
    <div>
      <p>Welcome to Dashboard page</p>
      <Button
        type="primary"
        htmlType="button"
        onClick={() => {
          logOut();
        }}
      >
        Logout
      </Button>
    </div>
  );
};

export default Dashboard;
