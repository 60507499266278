export const defaultObj = {
  loading: false,
  data: {},
  error: '',
};
export const defaultArr = {
  loading: false,
  data: [],
  error: '',
};
