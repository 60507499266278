import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowUpSvg } from 'src/assets/icons/ArrowUp.svg';
import { ReactComponent as ClockSvg } from 'src/assets/icons/Clock.svg';
import { ReactComponent as CloseSvg } from 'src/assets/icons/Close.svg';
import { Button, Icon } from 'src/components';
import { getCheckedInTime } from 'src/utils/date';
import ActionModal from './ActionModal';
import classes from './CheckIn.module.scss';

interface Props {
  className?: string;
  onSubmit?: (fn?: any) => void;
  timeString?: string;
  submitLoading?: boolean;
}

const CheckIn = ({ className, onSubmit, timeString, submitLoading }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [isExpand, setIsExpand] = useState(false);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const resetState = () => {
    setIsExpand(false);
    setIsCheckOut(false);
    setIsConfirm(false);
  };
  const actionModalProps = {
    isCheck: isCheckOut,
    setIsCheck: setIsCheckOut,
    isConfirm,
    setIsConfirm,
    onSubmit: () => {
      onSubmit(resetState);
    },
    text: (
      <>
        {isConfirm
          ? t('jobDetail.inspection.checkinTitle.3')
          : `${t('jobDetail.inspection.checkinTitle.2')} ${getCheckedInTime()}`}
      </>
    ),
    submitButtonText: t('buttons.yesCheckout', { ns: 'common' }),
    submitLoading,
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <span>
        <Icon size={24}>
          <ClockSvg />
        </Icon>
        {t('jobDetail.inspection.checkinTitle.4')}{' '}
        {getCheckedInTime(timeString, true)}
      </span>
      <span
        onClick={() => {
          setIsExpand(!isExpand);
        }}
      >
        {isExpand
          ? t('buttons.close', { ns: 'common' })
          : t('buttons.checkoutHere', { ns: 'common' })}
        <Icon size={20} notMargin pointer>
          {isExpand ? <CloseSvg /> : <ArrowUpSvg />}
        </Icon>
      </span>
      {isExpand && (
        <div className={classes.expandContent}>
          <span>{t('jobDetail.inspection.checkoutTitle.3')}</span>
          <Button
            type="primary"
            size="large"
            onClick={() => {
              setIsConfirm(true);
            }}
          >
            {t('buttons.checkout', { ns: 'common' })}
          </Button>
        </div>
      )}
      <ActionModal {...actionModalProps} />
    </div>
  );
};

export default CheckIn;
