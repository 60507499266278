import React from 'react';
import { Button, Modal } from 'src/components';
import classes from './Modal.module.scss';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  cancelProps: any;
  okProps: any;
  children: React.ReactNode;
}

const WarningModal = ({
  isOpen,
  onClose,
  cancelProps,
  okProps,
  children,
}: Props) => {
  return (
    <Modal
      open={isOpen}
      warning
      onClose={onClose}
      closable={false}
      maskClosable={false}
    >
      <div className={classes.warningContent}>{children}</div>
      <div className={classes.warningFooter}>
        <Button type="default" size="large" color="white" {...cancelProps} />
        <Button type="primary" size="large" {...okProps} />
      </div>
    </Modal>
  );
};

export default WarningModal;
