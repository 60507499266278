import i18n from 'i18next';
import auth_cn from 'src/pages/Auth/translations/cn.json';
import auth_en from 'src/pages/Auth/translations/en.json';
import chat_en from 'src/pages/Chat/translations/en.json';
import jobRequests_cn from 'src/pages/JobRequests/translations/cn.json';
import jobRequests_en from 'src/pages/JobRequests/translations/en.json';
import common_cn from 'src/translations/cn.json';
import common_en from 'src/translations/en.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n.init({
  fallbackLng: 'en', // en, cn
  // debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      jobRequests: jobRequests_en,
      common: common_en,
      auth: auth_en,
      chat: chat_en,
    },
    cn: {
      jobRequests: jobRequests_cn,
      common: common_cn,
      auth: auth_cn,
      chat: chat_en,
    },
  },
});

export default i18n;
