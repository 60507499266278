import { LoadingOutlined } from '@ant-design/icons';
import { Spin as AntdSpin, SpinProps } from 'antd';
import cn from 'classnames';
import classes from './Spin.module.scss';

type Props = SpinProps & {
  className?: string;
  circle?: boolean;
  color?: string;
};
const Spin = ({ className, circle, color, ...other }: Props) => {
  return (
    <AntdSpin
      {...other}
      indicator={
        circle ? (
          <LoadingOutlined style={{ fontSize: 14, color }} spin />
        ) : (
          other.indicator
        )
      }
      className={cn(
        classes.wrapper,
        {
          [classes.loading]: other.spinning,
          [classes.circle]: circle,
        },
        className
      )}
    />
  );
};

export default Spin;
