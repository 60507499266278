import { combineReducers } from 'redux';
import authTypes from '../types/auth';
import AuthReducer from './auth';
import ChatReducer from './chat';
import JobReducer from './job';
import JobDetailReducer from './jobDetail';
import SharedReducer from './shared';

const reducers = {
  auth: AuthReducer,
  job: JobReducer,
  jobDetail: JobDetailReducer,
  chat: ChatReducer,
  shared: SharedReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer = (state, action) => {
  if (action.type === authTypes.LOG_OUT) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export type ReducerName = keyof typeof reducers;

export default rootReducer;
