import React from 'react';
import { ReactComponent as AiIcon } from 'src/assets/icons/files/AI.svg';
import { ReactComponent as DocIcon } from 'src/assets/icons/files/DOC.svg';
import { ReactComponent as GeneralIcon } from 'src/assets/icons/files/General.svg';
import { ReactComponent as JpgIcon } from 'src/assets/icons/files/JPG.svg';
import { ReactComponent as PdfIcon } from 'src/assets/icons/files/PDF.svg';
import { ReactComponent as PptIcon } from 'src/assets/icons/files/PPT.svg';
import { ReactComponent as PsIcon } from 'src/assets/icons/files/PS.svg';
import { ReactComponent as XlsIcon } from 'src/assets/icons/files/XLS.svg';
import { ReactComponent as ZipIcon } from 'src/assets/icons/files/ZIP.svg';

const isImage = (fileName) => {
  return fileName.match(/\.(jpg|jpeg|png|gif)$/i);
};

const FileIcon = ({ fileName }) => {
  if (isImage(fileName)) return <JpgIcon />;

  const fileExtesion = fileName.split('.').pop();

  if (fileExtesion === 'pdf') return <PdfIcon />;
  if (fileExtesion === 'ai') return <AiIcon />;
  if (fileExtesion === 'psd') return <PsIcon />;
  if (fileExtesion === 'zip') return <ZipIcon />;

  if (
    fileExtesion === 'doc' ||
    fileExtesion === 'docx' ||
    fileExtesion === 'docm' ||
    fileExtesion === 'dot'
  )
    return <DocIcon />;

  if (
    fileExtesion === 'pptx' ||
    fileExtesion === 'pptm' ||
    fileExtesion === 'ppt'
  )
    return <PptIcon />;

  if (
    fileExtesion === 'xlsx' ||
    fileExtesion === 'xlsm' ||
    fileExtesion === 'xlsb' ||
    fileExtesion === 'xls'
  )
    return <XlsIcon />;

  return <GeneralIcon />;
};

export default FileIcon;
