import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { FormItem } from 'src/components';
import { Input, Radio } from 'src/components/FormFields';
import {
  FIELD_TYPES,
  PARSED_CUSTOM_FIELDS,
  SECTION_IGNORE_FIELD_NAMES,
} from 'src/helpers/constants';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { translateFreeText } from 'src/utils/languagues';
import { capitalize } from 'src/utils/text';
import ActionCheckField from './components/ActionCheckField';
import Add1Field from './components/Add1Field';
import AddField from './components/AddField';
import AttachmentField from './components/AttachmentField';
import MediaField from './components/MediaField';
import { getFieldSub, getSubColor, isSection1InputField } from './utils';

const FieldElements = ({
  formEl,
  form,
  thisInspectionData,
  handleAction,
  setIsSavedDraft,
  t,
}) => {
  return (
    <>
      {(formEl.fields || []).map((field, j) => {
        const hideItem = SECTION_IGNORE_FIELD_NAMES.includes(
          field.originalName
        );
        const commonProps = {
          name: field.name,
        };

        return (
          <React.Fragment key={j}>
            {field.editable === false ? (
              field.content
            ) : (
              <FormItem
                shouldUpdate
                className={cn({
                  ['hide']:
                    hideItem || field.show === false || field.enabled === false,
                })}
              >
                {() => {
                  const values = form.getFieldsValue();
                  const limit = isSection1InputField(formEl.name, field)
                    ? thisInspectionData.orderQty
                    : '';
                  const sub = getFieldSub({
                    sectionName: formEl.name,
                    field,
                    form,
                    limit,
                    t,
                  });

                  const subColor = getSubColor({
                    sectionName: formEl.name,
                    field,
                    fields: formEl.fields,
                    form,
                    limit,
                  });
                  const fieldAction = values[field.name] || {};
                  const fieldHasAction =
                    !isEmpty(fieldAction) &&
                    PARSED_CUSTOM_FIELDS.includes(field.type);
                  const thisTag = translateFreeText(
                    fieldAction?.classification || '',
                    t
                  );
                  const tRadioOptions = (field.options || []).map((option) => ({
                    ...option,
                    label: translateFreeText(option.label, t),
                  }));
                  return (
                    <FormSection
                      title={translateFreeText(field.title, t)}
                      required={field.required && !thisTag}
                      sub={sub}
                      subColor={subColor}
                      des={field.des}
                      tag={capitalize(thisTag)}
                      limit={limit}
                      borderHeader={[
                        FIELD_TYPES.ADD,
                        FIELD_TYPES.ACTION_CHECK,
                      ].includes(field.type)}
                      actionOnHeader={
                        [FIELD_TYPES.ADD1, FIELD_TYPES.ACTION_CHECK].includes(
                          field.type
                        ) || fieldHasAction
                      }
                      editMode={
                        [FIELD_TYPES.ACTION_CHECK].includes(field.type) ||
                        fieldHasAction
                      }
                    >
                      <FormItem name={field.name}>
                        {field.type === FIELD_TYPES.ADD ? (
                          <AddField
                            {...commonProps}
                            action={fieldAction}
                            setAction={handleAction(field.name)}
                            field={field}
                          />
                        ) : field.type === FIELD_TYPES.ADD1 ? (
                          <Add1Field
                            {...commonProps}
                            action={fieldAction}
                            setAction={handleAction(field.name)}
                            field={field}
                            thisInspectionData={thisInspectionData}
                          />
                        ) : field.type === FIELD_TYPES.ACTION_CHECK ? (
                          <ActionCheckField />
                        ) : field.type === FIELD_TYPES.MEDIA ? (
                          <MediaField
                            {...commonProps}
                            setIsSavedDraft={setIsSavedDraft}
                            form={form}
                          />
                        ) : field.type === FIELD_TYPES.ATTACHMENT ? (
                          <AttachmentField
                            {...commonProps}
                            setIsSavedDraft={setIsSavedDraft}
                            form={form}
                          />
                        ) : field.type === FIELD_TYPES.RADIO ? (
                          <Radio
                            {...commonProps}
                            options={tRadioOptions}
                            fullWidth
                          />
                        ) : (
                          <Input
                            {...commonProps}
                            placeholder={field.placeholder}
                            type={
                              field.type === 'textarea' ? 'textarea' : 'text'
                            }
                            size="large"
                          />
                        )}
                      </FormItem>
                    </FormSection>
                  );
                }}
              </FormItem>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default FieldElements;
