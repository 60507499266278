import { STATUS_VALUES } from 'src/helpers/constants';
import { useReducerData } from 'src/store/hooks';

const useJobDetailStatus = () => {
  const jobDetail = useReducerData('jobDetail', 'jobDetail.data', {});
  const jobStatus = jobDetail.status;
  const canRejectAccept = [STATUS_VALUES.WAITING_TO_ACCEPT].includes(jobStatus);
  const canViewReport = [
    STATUS_VALUES.IN_PROGRESS,
    STATUS_VALUES.COMPLETED,
    STATUS_VALUES.INSPECTION_RELEASED_WAIVER,
    STATUS_VALUES.INSPECTION_ACCEPTED,
    STATUS_VALUES.INSPECTION_REJECTED,
  ].includes(jobStatus);
  const canEditReport = [STATUS_VALUES.IN_PROGRESS].includes(jobStatus);
  const canSubmitReport = [STATUS_VALUES.IN_PROGRESS].includes(jobStatus);
  const canCheckIn = [STATUS_VALUES.SCHEDULED].includes(jobStatus);
  const canCheckout = [STATUS_VALUES.IN_PROGRESS].includes(jobStatus);
  const canBackToReport = [STATUS_VALUES.IN_PROGRESS].includes(jobStatus);

  return {
    canRejectAccept,
    canViewReport,
    canEditReport,
    canSubmitReport,
    canCheckIn,
    canCheckout,
    canBackToReport,
  };
};

export default useJobDetailStatus;
