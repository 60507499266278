import { Dispatch } from 'redux';

import Types from '../types/chat';

export const syncChat =
  (messages, isSyncAll = false) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_CHAT,
      payload: {
        messages,
        isSyncAll,
      },
    });
  };
