import { Upload as AntdUpload, UploadProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import classes from './Upload.module.scss';

const { Dragger } = AntdUpload;

type Props = UploadProps & {
  className?: string;
};

const Upload = ({ className, ...other }: Props) => {
  return <AntdUpload {...other} className={cn(classes.wrapper, className)} />;
};

export { Dragger };
export default Upload;
