import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { JobStatus, Tooltip } from 'src/components';
import classes from './FormSection.module.scss';

interface Props {
  children: React.ReactNode;
  title: string | React.ReactNode;
  required?: boolean;
  sub?: React.ReactNode;
  des?: React.ReactNode;
  subColor?: 'red' | 'green' | undefined | '';
  className?: string;
  tag?: string;
  borderHeader?: boolean;
  actionOnHeader?: boolean;
  editMode?: boolean;
  limit?: string | React.ReactNode;
  onBox?: boolean;
  hide?: boolean;
}
const FormSection = ({
  children,
  title,
  required,
  sub,
  className,
  subColor = 'green',
  des,
  tag,
  borderHeader,
  actionOnHeader,
  editMode,
  limit,
  onBox,
  hide,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.borderHeader]: borderHeader,
          [classes.actionOnHeader]: actionOnHeader,
          [classes.editMode]: editMode,
          [classes.hasLimit]: !!limit,
          [classes.onBox]: onBox,
          [classes.required]: required,
          ['hide']: hide,
        },
        className
      )}
    >
      <div className={classes.header}>
        <h2>
          <Tooltip title={title}>
            <p>{title}</p>
          </Tooltip>
          {!!tag && <JobStatus status={tag} className={classes.tag} />}
        </h2>
        {required && (
          <span className={classes.requiredMark}>
            ({t('form.required', { ns: 'common' })})
          </span>
        )}
      </div>
      {!!des && <div className={classes.des}>{des}</div>}
      <div className={classes.content}>
        <div className={classes.childrenWrapper}>{children}</div>
        {!!limit && <div className={classes.limit}>/ {limit}</div>}
        {!!sub && (
          <div className={cn(classes.sub, classes[subColor])}>{sub}</div>
        )}
      </div>
    </div>
  );
};

export default FormSection;
