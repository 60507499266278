export const urlify = (text: string) => {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  const message = (text || '').replace(urlRegex, (url): any => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink;
    }
    return `<a href="${hyperlink}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  return message;
};
export const capitalize = (val) => {
  if (typeof val === 'string' && !!val) {
    const valArr = val.split('');
    valArr[0] = valArr[0].toUpperCase();
    return valArr.join('');
  }
  return '';
};
export const capitalizeWholeString = (val) => {
  if (!val && typeof val !== 'string') return val || '';
  const valArr = val.split(' ').map((v) => capitalize(v));
  return valArr.join(' ');
};
