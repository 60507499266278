import { Form as AntdForm } from 'antd';
import cn from 'classnames';
import classes from './Form.module.scss';

const { Item, useForm, Provider } = AntdForm;
const Form = ({ className = '', ...other }) => {
  return (
    <AntdForm
      layout="vertical"
      {...other}
      className={cn(classes.wrapper, className)}
    />
  );
};
const FormItem = Item;
const FormProvider = Provider;

export { FormItem, useForm, FormProvider };
export default Form;
