import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'src/components';
import { getCheckedInTime } from 'src/utils/date';
import ActionModal from './ActionModal';
import classes from './CheckIn.module.scss';

interface Props {
  className?: string;
  onSubmit: (fn?: any) => void;
  onBack?: () => void;
}

const CheckInFlow = ({ className, onSubmit, onBack }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isCheckIn, setIsCheckIn] = useState(false);
  const resetModal = () => {
    setIsConfirm(false);
    setIsCheckIn(false);
  };
  const actionModalProps = {
    isCheck: isCheckIn,
    setIsCheck: setIsCheckIn,
    isConfirm,
    setIsConfirm,
    onSubmit: () => {
      onSubmit(resetModal);
    },
    text: (
      <>
        {isConfirm
          ? t('jobDetail.inspection.checkinTitle.1')
          : `${t('jobDetail.inspection.checkinTitle.2')} ${getCheckedInTime()}`}
      </>
    ),
    submitButtonText: t('buttons.yesCheckin', { ns: 'common' }),
    onBack,
  };

  useEffect(() => {
    resetModal();
  }, []);

  return (
    <div
      className={cn(
        classes.checkInFlow,
        {
          [classes.box]: isCheckIn || isConfirm,
        },
        className
      )}
    >
      {!isCheckIn && !isConfirm && (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            setIsConfirm(true);
          }}
          fullWidth
        >
          {t('buttons.checkin', { ns: 'common' })}
        </Button>
      )}
      <ActionModal {...actionModalProps} />
    </div>
  );
};

export default CheckInFlow;
