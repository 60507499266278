import cn from 'classnames';
import React from 'react';
import classes from './Public.module.scss';
import Header from './components/Header';
import Nav from './components/Nav';
import { useActions } from './selectorData';

interface Props {
  children: React.ReactNode;
  hideHeader?: boolean;
  innerColor?: 'default' | 'white' | 'gray1';
  notPadding?: boolean;
  hideNav?: boolean;
}

const MainLayout: React.FC<Props> = ({
  children,
  hideHeader,
  innerColor = 'default',
  notPadding,
  hideNav,
}) => {
  const { logOut } = useActions();

  return (
    <div
      className={cn(
        classes.publicLayoutWrapper,
        {
          [classes.hideHeader]: hideHeader,
          [classes.notPadding]: notPadding,
          [classes.hideNav]: hideNav,
        },
        classes[`${innerColor}Inner`]
      )}
      id="i-main-layout-wrapper"
    >
      {!hideHeader && (
        <Header
          onLogout={() => {
            logOut();
          }}
        />
      )}
      <div className={classes.inner}>{children}</div>
      {!hideNav && <Nav />}
    </div>
  );
};

export default MainLayout;
