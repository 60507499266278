import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'src/components';
import { Input } from 'src/components/FormFields';
import WarningModal from 'src/components/Modal/WarningModal';
import { getMomentDate } from 'src/utils/date';
import classes from './ESignModal.module.scss';
import SignUpload from './SignUpload';
import Signature from './Signature';
import { SIGNATURE_ACTIONS, WARNING_ACTIONS } from './constants';

interface Props {
  opened: boolean;
  onClose: () => void;
  className?: string;
  onSubmit: (values: any) => void;
  editObj?: any;
}

let deleteUploadFileFn = null;

const ESignModal = ({
  opened,
  onClose,
  className,
  onSubmit,
  editObj,
}: Props) => {
  const hasEditObj = !!editObj?.date;
  const [signatureAction, setSignatureAction] = useState(SIGNATURE_ACTIONS.PAD);
  const isSignaturePad = signatureAction === SIGNATURE_ACTIONS.PAD;
  const [confirmAction, setConfirmAction] = useState('');
  const isConfirmSwitchToPad = confirmAction === WARNING_ACTIONS.SWITCH_TO_PAD;
  const isConfirmSwitchToUpload =
    confirmAction === WARNING_ACTIONS.SWITCH_TO_UPLOAD;
  const isConfirmDeleteFile = confirmAction === WARNING_ACTIONS.DELETE_FILE;
  const isConfirmCloseModal = confirmAction === WARNING_ACTIONS.CLOSE_MODAL;
  const [formValues, setFormValues] = useState<any>({});
  const isSubmitDisabled =
    !formValues?.name ||
    !formValues?.title ||
    !formValues?.companyName ||
    (!formValues?.signature && !formValues.signatureFile);
  const resetState = () => {
    if (hasEditObj) {
      setSignatureAction(
        editObj?.signatureFile
          ? SIGNATURE_ACTIONS.UPLOAD
          : SIGNATURE_ACTIONS.PAD
      );
      setFormValues({
        ...editObj,
      });
    } else {
      setSignatureAction(SIGNATURE_ACTIONS.PAD);
      setFormValues(editObj || {});
    }
    deleteUploadFileFn = null;
    setConfirmAction('');
  };
  const switchSignatureAction = () => {
    setSignatureAction(
      isSignaturePad ? SIGNATURE_ACTIONS.UPLOAD : SIGNATURE_ACTIONS.PAD
    );
  };
  const warningModalProps = {
    onClose: () => {
      setConfirmAction('');
    },
    isOpen: !!confirmAction,
    children: (
      <span>
        {isConfirmSwitchToPad &&
          'Are you going back to signature pad? Uploaded file will not be saved.'}
        {isConfirmSwitchToUpload &&
          'Are you going to upload signature? Current signature will not be saved.'}
        {isConfirmDeleteFile && 'Do you want to delete this file?'}
        {isConfirmCloseModal && (
          <>
            Are you sure you want to close?
            <br />
            All data will not be saved.
          </>
        )}
      </span>
    ),
    cancelProps: {
      children: 'Cancel',
      onClick: () => {
        setConfirmAction('');
      },
    },
    okProps: {
      children: 'Yes',
      onClick: () => {
        if (!isConfirmCloseModal) {
          setFormValues({
            ...formValues,
            signature: null,
            signatureFile: null,
          });
          if (isConfirmDeleteFile) {
            if (deleteUploadFileFn) deleteUploadFileFn();
          } else {
            if (
              [
                WARNING_ACTIONS.SWITCH_TO_PAD,
                WARNING_ACTIONS.SWITCH_TO_UPLOAD,
              ].includes(confirmAction)
            ) {
              switchSignatureAction();
            }
          }
          setConfirmAction('');
        } else {
          setConfirmAction('');
          onClose();
        }
      },
    },
  };

  useEffect(() => {
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <>
      <Modal
        open={opened}
        onClose={() => {
          setConfirmAction(WARNING_ACTIONS.CLOSE_MODAL);
        }}
        bottom
        title="E-signature"
        className={cn(classes.modalWrapper, className)}
      >
        <div className={classes.modalContent}>
          <p>
            Kindly review and ensure the entered information is accurate before
            signing. You may sign below or upload a signature image.
          </p>
          <div className={classes.esignBlock}>
            {isSignaturePad ? (
              <Signature
                className={classes.signature}
                handleStart={() => {}}
                handleClear={() => {
                  setFormValues({
                    ...formValues,
                    signature: null,
                  });
                }}
                onSignatureChange={(sValue) => {
                  setFormValues({
                    ...formValues,
                    signature: sValue,
                  });
                }}
                canvasProps={{
                  width: 372,
                  height: 164,
                }}
                title="Signature"
              />
            ) : (
              <SignUpload
                title="Signature"
                onFileChanged={(tFile) => {
                  setFormValues({
                    ...formValues,
                    signatureFile: tFile,
                  });
                }}
                onDelete={(cb) => {
                  setConfirmAction(WARNING_ACTIONS.DELETE_FILE);
                  deleteUploadFileFn = cb;
                }}
              />
            )}
          </div>
          <div className={classes.switchBlock}>
            <span>
              Signature Date:{' '}
              {!!formValues?.signature?.val || !!formValues?.signatureFile?.name
                ? getMomentDate().format('DD MMMM YYYY HH:mm')
                : '/'}
            </span>
            <div>
              <Button
                type="primary"
                ghost
                size="middle"
                onClick={() => {
                  setConfirmAction(
                    isSignaturePad
                      ? WARNING_ACTIONS.SWITCH_TO_UPLOAD
                      : WARNING_ACTIONS.SWITCH_TO_PAD
                  );
                }}
              >
                Switch To Signature {isSignaturePad ? 'Upload' : 'Pad'}
              </Button>
            </div>
          </div>
          <div className={classes.inputBlock}>
            <label>Name</label>
            <Input
              name="name"
              placeholder="Enter here"
              size="large"
              value={formValues?.name}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  name: e.target.value,
                });
              }}
            />
          </div>
          <div className={classes.inputBlock}>
            <label>Title</label>
            <Input
              name="Title"
              placeholder="Enter here"
              size="large"
              value={formValues?.title}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  title: e.target.value,
                });
              }}
            />
          </div>
          <div className={cn(classes.inputBlock, classes.last)}>
            <label>Company Name</label>
            <Input
              name="companyName"
              placeholder="Enter here"
              size="large"
              value={formValues?.companyName}
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  companyName: e.target.value,
                });
              }}
            />
          </div>
          <div className={classes.buttons}>
            <Button
              type="ghost"
              size="large"
              onClick={() => {
                setConfirmAction(WARNING_ACTIONS.CLOSE_MODAL);
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              size="large"
              disabled={isSubmitDisabled}
              onClick={() => {
                onSubmit({
                  ...formValues,
                  date: getMomentDate().format('DD MMMM YYYY HH:mm'),
                });
              }}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <WarningModal {...warningModalProps} />
    </>
  );
};

export default ESignModal;
