import { Calendar as AntdCalendar, CalendarProps } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { ReactComponent as ArrowLeftSvg } from 'src/assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight1Svg } from 'src/assets/icons/ArrowRight1.svg';
import Icon from '../Icon';
import classes from './Calendar.module.scss';

type DateType = /*unresolved*/ any;
type Props = CalendarProps<DateType> & {
  className?: string;
  weekView?: boolean;
  datesHasJob?: string[];
};

const Header = ({ date, onChange }) => {
  return (
    <div className={classes.header}>
      <Icon
        size={24}
        pointer
        onClick={() => {
          onChange(date.clone().subtract(1, 'month'));
        }}
        // disabled={weekView}
      >
        <ArrowLeftSvg />
      </Icon>
      <span className={classes.date}>{date.format('MMMM, YYYY')}</span>
      <Icon
        size={24}
        pointer
        onClick={() => {
          onChange(date.clone().add(1, 'month'));
        }}
        // disabled={weekView}
      >
        <ArrowRight1Svg />
      </Icon>
    </div>
  );
};

const Calendar = ({ className, weekView, datesHasJob, ...other }: Props) => {
  const thisValue = other.value || '';
  const currentWeekStart = (thisValue ? thisValue.clone() : moment()).startOf(
    'week'
  );
  const currentWeekEnd = (thisValue ? thisValue.clone() : moment()).endOf(
    'week'
  );
  const addProps = {} as any;
  if (weekView) {
    addProps.validRange = [currentWeekStart, currentWeekEnd];
  }
  return (
    <AntdCalendar
      fullscreen={false}
      headerRender={({ value, onChange }) => {
        return <Header date={value} onChange={onChange} />;
      }}
      dateCellRender={(date) => {
        if ((datesHasJob || []).includes(date.format('DD/MM/YYYY'))) {
          return <span className="i-calendar-mark" />;
        }
      }}
      {...addProps}
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.weekView]: weekView,
        },
        className
      )}
    />
  );
};

export default Calendar;
