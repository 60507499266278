import { Tag as AntdTag, TagProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import classes from './Tag.module.scss';

type Props = TagProps & {
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

const Tag = ({ className, size = 'medium', ...other }: Props) => {
  return (
    <AntdTag
      {...other}
      className={cn(classes.wrapper, classes[size], className)}
    />
  );
};

export default Tag;
