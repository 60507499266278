import { SearchOutlined } from '@ant-design/icons';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'src/components';
import { Input } from 'src/components/FormFields';
import { SECTION_3_NAME } from 'src/helpers/constants';
import FieldElements from 'src/pages/JobRequests/JobDetail/SubContent/SubInspection/FieldElements';
import classes from './DefectSearchModal.module.scss';
import NotFound from './NotFound';

interface Props {
  opened: boolean;
  onClose: () => void;
  className?: string;
  formSettings: any[];
  form: any;
  thisInspectionData: any;
  setIsSavedDraft: any;
}

const DefectSearchModal = ({
  opened,
  onClose,
  className,
  formSettings,
  form,
  thisInspectionData,
  setIsSavedDraft,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const inputRef = React.useRef(null);
  const [val, setVal] = React.useState('');
  const filteredFormSettings = cloneDeep(formSettings || [])
    .filter((formEl) => formEl.name === SECTION_3_NAME && formEl.enabled)
    .map((formEl) => {
      formEl.fields = (formEl.fields || [])
        .filter((field) =>
          (field.name || '').includes('workmanship_step_defects_')
        )
        .map((field) => {
          field.show = (field.label || '')
            .toLowerCase()
            .includes(val.toLowerCase());
          return field;
        });
      return formEl;
    });
  const numberOfFields =
    (filteredFormSettings?.[0]?.fields || []).filter((field) => field.show)
      .length || 0;
  const handleAction = (name) => (tAction) => {
    const preVal = val;
    setVal('');
    form.setFieldValue(name, tAction);
    setTimeout(() => {
      setVal(preVal);
      setIsSavedDraft(false);
    }, 100);
  };

  React.useEffect(() => {
    if (opened && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [opened]);

  return (
    <Modal
      open={opened}
      onClose={onClose}
      bottom
      title="Search Defect"
      className={cn(classes.modalWrapper, className)}
    >
      <div className={classes.modalContent}>
        <Input
          placeholder={'Search defect name'}
          type={'text'}
          size="large"
          prefix={(<SearchOutlined />) as any}
          allowClear
          value={val}
          onChange={(e) => setVal(e.target.value)}
          inputRef={inputRef}
        />
        {numberOfFields > 0 && !!val ? (
          <div>
            {filteredFormSettings.map((formEl, i) => {
              const fieldElementProps = {
                formEl,
                form,
                thisInspectionData,
                handleAction,
                setIsSavedDraft,
                t,
              };
              return (
                <div key={i}>
                  <FieldElements {...fieldElementProps} />
                </div>
              );
            })}
          </div>
        ) : (
          <NotFound />
        )}
      </div>
    </Modal>
  );
};

export default DefectSearchModal;
