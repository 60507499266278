import cn from 'classnames';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CallSvg } from 'src/assets/icons/Call.svg';
import { ReactComponent as CopySvg } from 'src/assets/icons/Copy.svg';
import { Button, CopyText, Icon, Modal, copyTextFn } from 'src/components';
import { JOB_INFO_SUB_KEY } from 'src/helpers/constants';
import classes from './SubInfo.module.scss';

export type Item = {
  label?: string | React.ReactNode;
  value?: string | React.ReactNode;
  copyDirectly?: boolean;
  copyByModal?: boolean;
  minWidth?: number;
};
interface Props {
  icon?: React.ReactNode;
  border?: boolean;
  listBox?: boolean;
  box?: boolean;
  boxColor?: string;
  title?: string;
  titles?: string[];
  items?: Item[] | Item[][];
  itemsAsArray?: boolean;
  arrayHasBorder?: boolean;
  children?: React.ReactNode;
  className?: string;
}

interface ItemsProps {
  items: Item[] | Item[][];
  onModal?: (item: Item) => void;
}

const Items = ({ items, onModal }: ItemsProps) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  return (
    <>
      {(items || []).map((item, index) => {
        const { label, value, copyDirectly, copyByModal } = item;
        if (label === JOB_INFO_SUB_KEY) {
          if (isArray(value)) {
            return (
              <div className={classes.subBoxWrapper} key={index}>
                {value.map((subValue, subIndex) => {
                  return (
                    <div className={classes.subBox} key={subIndex}>
                      <div>
                        <span>{t('jobDetail.information.po')}</span>
                        <span>{subValue.poNo || '-'}</span>
                      </div>
                      <div>
                        <span>{t('jobDetail.information.customerName')}</span>
                        <span>{subValue.clientName || '-'}</span>
                      </div>
                      <div>
                        <span>{t('jobDetail.information.customerPO')}</span>
                        <span>{subValue.clientPONo || '-'}</span>
                      </div>
                      <div>
                        <span>{t('jobDetail.information.poQty')}</span>
                        <span>{subValue.orderQty || '0'}</span>
                      </div>
                      <div>
                        <span>{t('jobDetail.information.poNoOfCartons')}</span>
                        <span>{subValue.cartonsNo || '0'}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          }
          return <></>;
        }
        return (
          <li
            key={index}
            className={cn({
              [classes.itemNoLabel]: !label,
            })}
          >
            {!!label && (
              <span
                style={{
                  minWidth: item.minWidth ? `${item.minWidth}px` : undefined,
                }}
              >
                {label}
              </span>
            )}
            {(copyDirectly || copyByModal) && !!value ? (
              <CopyText
                text={value}
                tooltipText={`${label} ${t('text.copied', { ns: 'common' })}.`}
                onClick={
                  copyByModal
                    ? () => {
                        if (onModal) onModal(item);
                      }
                    : null
                }
              >
                {value}
              </CopyText>
            ) : (
              <span>{value || '-'}</span>
            )}
          </li>
        );
      })}
    </>
  );
};
const Section = ({
  icon,
  border,
  listBox,
  box,
  boxColor,
  title,
  items,
  itemsAsArray,
  children,
  titles,
  arrayHasBorder,
  className,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [modalObj, setModalObj] = useState({} as Item);
  const onClose = () => {
    setModalObj({});
  };
  const styleProps = {} as any;
  if (boxColor) {
    styleProps.background = boxColor;
  }
  const itemsProps = {
    onModal: (thisItem) => {
      setModalObj(thisItem);
    },
  };
  return (
    <div
      className={cn(
        classes.section,
        {
          [classes.border]: border,
          [classes.listBox]: listBox,
          [classes.box]: box,
          [classes.nonHeader]: !icon && !title,
        },
        className
      )}
      style={styleProps}
    >
      {(!!title || !!icon) && (
        <div>
          {!!icon && <Icon size={16}>{icon}</Icon>}
          {!!title && <h3>{title}</h3>}
        </div>
      )}
      {children ? (
        <div className={classes.childrenWrapper}>{children}</div>
      ) : (
        <>
          {itemsAsArray ? (
            <div
              className={cn(classes.itemAsArray, {
                [classes.arrayHasBorder]: arrayHasBorder,
              })}
            >
              {items.map((items1, index) => {
                const thisTitle = (titles || [])[index];
                return (
                  <React.Fragment key={index}>
                    <ul>
                      {!!thisTitle && <h3>{thisTitle}</h3>}
                      <Items items={items1} {...itemsProps} />
                    </ul>
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <ul>
              <Items items={items} {...itemsProps} />
            </ul>
          )}
        </>
      )}
      <Modal open={!isEmpty(modalObj)} onClose={onClose} warning>
        <Button
          type="ghost"
          size="large"
          fullWidth
          icon={<CallSvg />}
          color="white"
          onClick={() => {
            window.open(`tel:${modalObj.value}`, '_self');
            onClose();
          }}
        >
          {t('buttons.call', { ns: 'common' })}
        </Button>
        <Button
          type="ghost"
          size="large"
          fullWidth
          color="white"
          icon={<CopySvg />}
          onClick={() => {
            copyTextFn(
              modalObj.value,
              `${modalObj.label} ${t('text.copied', { ns: 'common' })}.`
            );
            onClose();
          }}
        >
          {t('buttons.copyContactNo', { ns: 'common' })}
        </Button>
      </Modal>
    </div>
  );
};

export default Section;
