import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, FormItem, useForm } from 'src/components';
import { Input } from 'src/components/FormFields';
import classes from './ResetPassword.module.scss';
import { useIndexData } from './selectorData';

const ResetPassword = () => {
  const { t } = useTranslation(['auth', 'common']);
  const params = useParams();
  const { token } = params;
  const navigate = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const [form] = useForm();
  const { isLoggedIn } = useIndexData();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('token', token);
    // TODO: check token is valid, if not, redirect to /login
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={classes.wrapper}>
      <h1>{t('resetPassword.title')}</h1>
      <Form
        form={form}
        name="forgot-password-form"
        onFinish={() => {
          // TODO: call api forgot password with values
          setIsSent(true);
        }}
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
      >
        {isSent ? (
          <div className={classes.sentSuccess}>
            <p>
              {t('resetPassword.passwordUpdated')}
              <br />
              <span
                onClick={() => {
                  navigate('/login');
                }}
              >
                {t('buttons.login', { ns: 'common' })}
              </span>
            </p>
          </div>
        ) : (
          <>
            <FormItem
              name="password"
              label={t('form.field.password.label', { ns: 'common' })}
              rules={[
                {
                  required: true,
                  message: t('form.validation.password', { ns: 'common' }),
                },
              ]}
            >
              <Input
                name="password"
                placeholder={t('form.field.password.label', { ns: 'common' })}
                type="password"
                size="large"
              />
            </FormItem>
            <FormItem
              name="confirmPassword"
              label={t('form.field.confirmPassword.label', { ns: 'common' })}
              rules={[
                {
                  required: true,
                  message: t('form.validation.confirmPassword', {
                    ns: 'common',
                  }),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        t('form.validation.invalidConfirmPassword', {
                          ns: 'common',
                        })
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                name="confirmPassword"
                placeholder={t('form.field.confirmPassword.label', {
                  ns: 'common',
                })}
                type="password"
                size="large"
              />
            </FormItem>
            <div className={classes.buttons}>
              <Button fullWidth type="primary" size="large" htmlType="submit">
                {t('buttons.resetPassword', { ns: 'common' })}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ResetPassword;
