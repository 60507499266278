import { Reducer } from 'redux';

import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/shared';

const initialState = {
  language: 'en',
  uploadFiles: {
    ...defaultObj,
  },
  uploadDocumentList: {
    ...defaultObj,
  },
};

const SharedReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    case Types.UPLOAD_FILES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadFiles: {
            ...prevState.uploadFiles,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadFiles: {
            loading: false,
            data: payload.data,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadFiles: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPLOAD_DOCUMENT_LIST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadDocumentList: {
            ...prevState.uploadDocumentList,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadDocumentList: {
            loading: false,
            data: payload.data,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadDocumentList: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default SharedReducer;
