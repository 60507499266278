/* eslint-disable prettier/prettier */
import cn from 'classnames';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import { ReactComponent as SmileSvg } from 'src/assets/icons/Smile.svg';
import {
  Alert,
  Button,
  Form,
  FormItem,
  Icon,
  Modal,
  useForm,
} from 'src/components';
import { Input, Select } from 'src/components/FormFields';
import {
  SECTION_3_DEFECT_CLASS_FIELD_NAME,
  SECTION_3_DEFECT_GROUP_FIELD_NAME,
  SECTION_3_DEFECT_NAME_FIELD_NAME,
} from 'src/helpers/constants';
import Section from 'src/pages/JobRequests/JobDetail/SubContent/SubInfo/Section';
import ActionModal from 'src/pages/JobRequests/JobDetail/components/CheckIn/ActionModal';
import FormSection from 'src/pages/JobRequests/JobDetail/components/FormSection';
import { getListFieldName } from 'src/pages/JobRequests/JobDetail/utils';
import { translateFreeText } from 'src/utils/languagues';
// import { capitalize } from 'src/utils/text';
import { AbsoluteButton } from '../Add1Field';
import MediaField from '../MediaField';
import classes from './AddField.module.scss';
import { getInitalValues } from './utils';

interface Props {
  className?: string;
  action: any;
  setAction: any;
  field: any;
  name: string;
}
const AddField = ({ className, action, setAction, field, name }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const { nameIndex } = getListFieldName(name);
  const thisFields = (field.items || [])[nameIndex] || [];
  const [form] = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const isNoAction = isEmpty(action);
  const nameValue =
    find(thisFields, { name: SECTION_3_DEFECT_NAME_FIELD_NAME })?.value ||
    'N/A';
  const groupValue =
    find(thisFields, { name: SECTION_3_DEFECT_GROUP_FIELD_NAME })?.value ||
    'N/A';
  // const classValue =
  //   find(thisFields, { name: SECTION_3_DEFECT_CLASS_FIELD_NAME })?.value ||
  //   'N/A';
  const classOptions =
    find(thisFields, { name: SECTION_3_DEFECT_CLASS_FIELD_NAME })?.options ||
    [];
  const actionModalProps = {
    isCheck: false,
    setIsCheck: (val) => {
      if (!val) {
        // Done
        setIsWarning(false);
        setAction(form.getFieldsValue());
        setIsOpen(false);
      } else {
        // Stay
        setIsWarning(false);
      }
    },
    isConfirm: isWarning,
    setIsConfirm: () => {},
    onSubmit: () => {},
    icon: <SmileSvg />,
    text: (
      <span className={classes.warningContent}>
        {' '}
        {!form.getFieldsValue().description &&
        (form.getFieldsValue().referenceMedia || []).length === 0
          ? 2
          : 1}{' '}
        {t(
          `text.missingRequiredField${
            !form.getFieldsValue().description &&
            (form.getFieldsValue().referenceMedia || []).length === 0
              ? 's'
              : ''
          }`,
          { ns: 'common' }
        )}
      </span>
    ),
    backButtonText: t('buttons.done', { ns: 'common' }),
    submitButtonText: t('buttons.stay', { ns: 'common' }),
  };
  const tQuantity = action?.quantity || 0;
  const tIntQuantity = tQuantity ? parseInt(tQuantity, 10) : 0;
  return (
    <div className={cn(classes.wrapper, className)}>
      {isNoAction ? (
        <>
          <Button
            type="default"
            size="large"
            color="white"
            onClick={() => {
              setAction({
                quantity: '0',
              });
            }}
          >
            {t('buttons.skip', { ns: 'common' })}
          </Button>
          <Button
            ghost
            type="primary"
            size="large"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            {t('buttons.add', { ns: 'common' })}
          </Button>
        </>
      ) : (
        <>
          <div className={classes.contentDisplay}>
            <div>
              {t('text.Quantity', { ns: 'common' })} {tQuantity}
            </div>
            {tIntQuantity > 0 && (
              <>
                {!!action?.description && <div>{action.description}</div>}
                {action?.referenceMedia &&
                  action?.referenceMedia.length > 0 && (
                    <ul>
                      {action?.referenceMedia.map((f) => {
                        const fileType = f.type || f.mineType || '';
                        return (
                          <li key={f.uid || f.id}>
                            {fileType.includes('video/') ? (
                              <video width="120" height="65" controls>
                                <source
                                  src={
                                    f.url
                                      ? f.url
                                      : f.originFileObj
                                      ? URL.createObjectURL(f.originFileObj)
                                      : ''
                                  }
                                  type={fileType}
                                />
                                {t('text.browserSupportVideo', {
                                  ns: 'common',
                                })}
                              </video>
                            ) : (
                              <img src={f.thumbUrl || f.url} alt={f.name} />
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                {(!action?.description ||
                  (action?.referenceMedia || []).length === 0) &&
                  !!action?.quantity &&
                  parseInt(action?.quantity, 10) > 0 && (
                    <Alert
                      message={t('text.descriptionMediaNotCompleted', {
                        ns: 'common',
                      })}
                      type="warning"
                      showIcon
                    />
                  )}
              </>
            )}
          </div>
          <AbsoluteButton
            headerHadBorder
            onClick={() => {
              setIsOpen(true);
              if (form) {
                form.setFieldsValue(getInitalValues(action));
              }
            }}
          >
            {t('buttons.edit', { ns: 'common' })}
            <Icon size={16} notMargin pointer>
              <ArrowRightSvg />
            </Icon>
          </AbsoluteButton>
        </>
      )}
      <Modal
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        bottom
        title={t('jobDetail.section3.reportDefect')}
      >
        <div className={classes.modalContent}>
          <Section
            items={[
              {
                label: t('jobDetail.section3.defectName'),
                value: nameValue,
              },
              {
                label: t('jobDetail.section3.defectGroup'),
                value: groupValue,
              },
              // {
              //   label: 'Defect Classification',
              //   value: capitalize(classValue),
              // },
            ]}
          />
          <Form
            form={form}
            name="add-field-form"
            onFinish={(values) => {
              const { quantity, description, referenceMedia } = values;
              const intQuantity = quantity ? parseInt(quantity) : 0;
              const thisMedia = referenceMedia || [];
              if (intQuantity > 0 && (!description || thisMedia.length === 0)) {
                setIsWarning(true);
              } else {
                setAction({
                  ...values,
                });
                setIsOpen(false);
              }
            }}
            initialValues={getInitalValues(action)}
          >
            <FormItem shouldUpdate className="no-margin">
              {() => {
                const values = form.getFieldsValue();
                const hideTheRest = parseInt(values.quantity || 0, 10) <= 0;
                return (
                  <>
                    {classOptions?.length > 0 && (
                      <FormSection
                        title={t('jobDetail.section3.defectClassification')}
                        onBox
                      >
                        <FormItem name={'classification'}>
                          <Select
                            placeholder={t('form.placeholder.select', {
                              ns: 'common',
                            })}
                            size="large"
                            options={classOptions.map((option) => {
                              return {
                                ...option,
                                label: translateFreeText(option.label, t),
                              };
                            })}
                          />
                        </FormItem>
                      </FormSection>
                    )}
                    <FormSection
                      title={t('text.Quantity', {
                        ns: 'common',
                      })}
                      onBox
                    >
                      <FormItem name={'quantity'}>
                        <Input
                          name={'quantity'}
                          placeholder={t('text.Quantity', {
                            ns: 'common',
                          })}
                          type={'text'}
                          size="large"
                          autoFocus
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('text.Description', {
                        ns: 'common',
                      })}
                      required
                      onBox
                      hide={hideTheRest}
                    >
                      <FormItem name={'description'}>
                        <Input
                          name={'description'}
                          placeholder={t('text.Description', {
                            ns: 'common',
                          })}
                          type={'textarea'}
                          size="large"
                        />
                      </FormItem>
                    </FormSection>
                    <FormSection
                      title={t('text.Reference Media', {
                        ns: 'common',
                      })}
                      required
                      onBox
                      hide={hideTheRest}
                      des={
                        <>
                          <span>
                            {t('form.placeholder.image1', {
                              ns: 'common',
                            })}
                          </span>
                          <span>
                            {t('form.placeholder.image2', {
                              ns: 'common',
                            })}
                          </span>
                        </>
                      }
                    >
                      <FormItem name={'referenceMedia'}>
                        <MediaField name="referenceMedia" form={form} />
                      </FormItem>
                    </FormSection>
                  </>
                );
              }}
            </FormItem>
            <Button type="primary" fullWidth htmlType="submit" size="large">
              {t('buttons.done', { ns: 'common' })}
            </Button>
          </Form>
        </div>
      </Modal>
      <ActionModal {...actionModalProps} />
    </div>
  );
};
export default AddField;
