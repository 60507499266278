import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ICWLogoSvg } from 'src/assets/icons/ICWLogo.svg';
import { ReactComponent as PlaceholderAvatarSvg } from 'src/assets/icons/PlaceholderAvatar.svg';
import { Button, Modal } from 'src/components';
import classes from './Header.module.scss';
import { useActions } from './selectorData';

const Header = ({ onLogout = null }) => {
  const { t, i18n } = useTranslation('common');
  const { syncUpdateLanguage } = useActions();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isSwitchLanguage, setIsSwitchLanguage] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };
  return (
    <div className={classes.headerWrapper}>
      <div
        className={classes.logo}
        onClick={() => {
          navigate('/');
        }}
      >
        <ICWLogoSvg />
        <h2>{t(`main.logoText`)}</h2>
      </div>
      <PlaceholderAvatarSvg
        className={classes.avatar}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <Modal open={isOpen} warning onClose={onClose}>
        {isSwitchLanguage ? (
          <>
            <Button
              type="ghost"
              size="large"
              fullWidth
              onClick={() => {
                i18n.changeLanguage('en');
                syncUpdateLanguage('en');
                setIsOpen(false);
                setIsSwitchLanguage(false);
              }}
              color="white"
            >
              English
            </Button>
            <Button
              type="ghost"
              size="large"
              fullWidth
              onClick={() => {
                i18n.changeLanguage('cn');
                syncUpdateLanguage('cn');
                setIsOpen(false);
                setIsSwitchLanguage(false);
              }}
              color="white"
            >
              简体中文
            </Button>
          </>
        ) : (
          <>
            <Button
              type="ghost"
              size="large"
              fullWidth
              onClick={() => {
                setIsSwitchLanguage(true);
              }}
              color="white"
            >
              {t(`buttons.languageSetting`)}
            </Button>
            <Button
              type="ghost"
              size="large"
              fullWidth
              onClick={() => {
                setIsOpen(false);
                if (onLogout) onLogout();
              }}
              color="white"
            >
              {t(`buttons.logout`)}
            </Button>
            <Button
              type="ghost"
              size="large"
              fullWidth
              onClick={onClose}
              color="white"
            >
              {t(`buttons.back`)}
            </Button>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Header;
