import cn from 'classnames';
import classes from './Heading.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}
const Heading = ({ className, children }: Props) => {
  return <h2 className={cn(classes.wrapper, className)}>{children}</h2>;
};

export default Heading;
