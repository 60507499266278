import { CheckCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Button, Form, FormItem, useForm } from 'src/components';
import { Input } from 'src/components/FormFields';
import classes from './ForgotPassword.module.scss';
import { useIndexData } from './selectorData';

const ForgotPassword = () => {
  const { t } = useTranslation(['auth', 'common']);
  const [isSent, setIsSent] = useState(false);
  const [form] = useForm();
  const { isLoggedIn } = useIndexData();

  if (isLoggedIn) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <div className={classes.wrapper}>
      <h1>{t('forgotPassword.title')}</h1>
      <Form
        form={form}
        name="forgot-password-form"
        onFinish={() => {
          // TODO: call api forgot password with values
          setIsSent(true);
        }}
        initialValues={{
          email: '',
        }}
      >
        {isSent ? (
          <div className={classes.sentSuccess}>
            <CheckCircleOutlined />
            <h2>{t('forgotPassword.sentTitle')}</h2>
            <p>
              {t('forgotPassword.didntReceive')}{' '}
              <span
                onClick={() => {
                  // TODO: call api forgot password with form values
                }}
              >
                {t('buttons.resendNow', { ns: 'common' })}
              </span>
            </p>
          </div>
        ) : (
          <>
            <FormItem
              name="email"
              label={t('form.field.email.label', { ns: 'common' })}
              rules={[
                {
                  required: true,
                  message: t('form.validation.email', { ns: 'common' }),
                },
                {
                  type: 'email',
                  message: t('form.validation.invalidEmail', {
                    ns: 'common',
                  }),
                },
              ]}
            >
              <Input
                name="email"
                placeholder={t('form.field.email.label', { ns: 'common' })}
                type="text"
                size="large"
              />
            </FormItem>
            <div className={classes.buttons}>
              <Button fullWidth type="primary" size="large" htmlType="submit">
                {t('buttons.resetMyPassword', { ns: 'common' })}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default ForgotPassword;
