import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import { useJobDetailStatus } from 'src/helpers/hooks';
import classes from './JobDetail.module.scss';

const SubmitButton = ({ jobId, needToSubmit }) => {
  const { canViewReport, canSubmitReport } = useJobDetailStatus();
  const { t } = useTranslation(['jobRequests', 'common']);
  const navigate = useNavigate();
  if (!canSubmitReport && !canViewReport) return <></>;
  return (
    <div className={classes.submitWrapper}>
      {canViewReport && (
        <Button
          type="default"
          size="large"
          onClick={() => {
            navigate(`/jobs/${jobId}/submission-preview`);
          }}
          color="white"
        >
          {t('buttons.viewReport', { ns: 'common' })}
        </Button>
      )}
      {needToSubmit && canSubmitReport && (
        <Button
          type="primary"
          size="large"
          onClick={() => {
            navigate(`/jobs/${jobId}/submission-preview`);
          }}
        >
          {t('buttons.submitReport', { ns: 'common' })}
        </Button>
      )}
    </div>
  );
};

export default SubmitButton;
