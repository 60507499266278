import { useEffect } from 'react';

const useStepScroll = (step, setStep, isStepClick) => {
  const handleScroll = (event) => {
    const scrollTop = event.srcElement.scrollTop + 150;
    const step1 = document.getElementById('step1');
    const step2 = document.getElementById('step2');
    const step3 = document.getElementById('step3');
    const step4 = document.getElementById('step4');
    const step5 = document.getElementById('step5');
    const step6 = document.getElementById('step6');
    if (!isStepClick) {
      if (step6?.offsetTop > 0 && scrollTop >= step6?.offsetTop) {
        setStep({
          ...step,
          active: 6,
        });
      } else if (step5?.offsetTop > 0 && scrollTop >= step5?.offsetTop) {
        setStep({
          ...step,
          active: 5,
        });
      } else if (step4?.offsetTop > 0 && scrollTop >= step4?.offsetTop) {
        setStep({
          ...step,
          active: 4,
        });
      } else if (step3?.offsetTop > 0 && scrollTop >= step3?.offsetTop) {
        setStep({
          ...step,
          active: 3,
        });
      } else if (step2?.offsetTop > 0 && scrollTop >= step2?.offsetTop) {
        setStep({
          ...step,
          active: 2,
        });
      } else if (
        step1.offsetTop > 0 &&
        (scrollTop < step2?.offsetTop ||
          scrollTop < step3?.offsetTop ||
          scrollTop < step4?.offsetTop ||
          scrollTop < step5?.offsetTop ||
          scrollTop < step6?.offsetTop)
      ) {
        setStep({
          ...step,
          active: 1,
        });
      }
    }
  };
  const publicLayoutWrapper = document.querySelector('#i-main-layout-wrapper');

  useEffect(() => {
    if (publicLayoutWrapper) {
      publicLayoutWrapper.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (publicLayoutWrapper) {
        publicLayoutWrapper.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publicLayoutWrapper, isStepClick]);
};

export default useStepScroll;
