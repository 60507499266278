import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DraftDocumentSvg } from 'src/assets/icons/DraftDocument.svg';
// import jobDetailMock from 'src/mocks/jobDetail';
import { JOB_INFO_SUB_KEY } from 'src/helpers/constants';
import { getJobInfo } from 'src/utils/job';
import { translateFreeText } from 'src/utils/languagues';
import Section from './Section';
import classes from './SubInfo.module.scss';

interface Props {
  className?: string;
  rejectReason?: string;
  data: any;
  currentUserId: number;
}

const SubInfo = ({ className, rejectReason, data, currentUserId }: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const {
    dateRange,
    address,
    name,
    contactPerson,
    phone,
    dateRangeNumber,
    generalInspectLevel,
    aqLevel,
    typeOfService,
    nameLocal,
    addressLocal,
    email,
    inspectionPOItems,
    jobNo,
    factoryLocation,
    checkinTime,
    checkoutTime,
  } = getJobInfo(data, t, currentUserId);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Section
        icon={<DraftDocumentSvg />}
        border
        title={t('jobDetail.information.jobInfo')}
        items={[
          {
            label: t('jobDetail.information.jobId'),
            value: jobNo,
          },
          {
            label: t('jobDetail.information.inspectionType'),
            value: typeOfService,
          },
          {
            label: t('jobDetail.information.generalInspectionLevel'),
            value: generalInspectLevel,
          },
          {
            label: t('jobDetail.information.acceptanceQualityLevel'),
            value: aqLevel,
          },
          {
            label: t('jobDetail.information.inspectionDate'),
            value: `${dateRange} (${dateRangeNumber} ${translateFreeText(
              `day${dateRangeNumber > 1 ? 's' : ''}`,
              t
            )})`,
          },
          {
            label: t('buttons.checkin', { ns: 'common' }),
            value: checkinTime,
          },
          {
            label: t('buttons.checkout', { ns: 'common' }),
            value: checkoutTime,
          },
        ]}
      />
      <Section
        icon={<DraftDocumentSvg />}
        border
        title={t('jobDetail.information.factoryInfo')}
        items={[
          {
            label: t('jobDetail.information.factoryName'),
            value: name,
          },
          {
            label: t('jobDetail.information.factoryNameLocal'),
            value: nameLocal,
          },
          {
            label: t('jobDetail.information.address'),
            value: address,
            copyDirectly: true,
          },
          {
            label: t('jobDetail.information.addressLocal'),
            value: addressLocal,
            copyDirectly: true,
          },
          {
            label: t('jobDetail.information.location'),
            value: factoryLocation,
          },
        ]}
      />
      <Section
        icon={<DraftDocumentSvg />}
        border
        title={t('jobDetail.information.contact')}
        items={[
          {
            label: t('jobDetail.information.contactPerson'),
            value: contactPerson,
          },
          {
            label: t('jobDetail.information.contactNo'),
            value: phone,
            copyByModal: true,
          },
          {
            label: t('jobDetail.information.emailAddress'),
            value: email,
            copyDirectly: true,
          },
        ]}
      />
      <Section
        icon={<DraftDocumentSvg />}
        listBox
        border={!!rejectReason}
        title={t('jobDetail.information.itemInfo')}
        itemsAsArray
        items={inspectionPOItems.map((item) => {
          let totalOrderQty = 0;
          let totalCartonsNo = 0;
          const subPO = item.inspectionSubPOItems || [];
          subPO.forEach((sub) => {
            totalOrderQty = totalOrderQty + parseInt(sub.orderQty, 10);
            totalCartonsNo = totalCartonsNo + parseInt(sub.cartonsNo, 10);
          });
          return [
            {
              label: t('jobDetail.information.itemName'),
              value: item.productProfile?.name,
            },
            {
              label: t('jobDetail.information.itemNo'),
              value: item.productProfile?.itemNo,
            },
            {
              label: t('jobDetail.information.categories'),
              value: (item.productProfile?.categories || [])
                .map((c) => c.name_en)
                .join(', '),
            },
            {
              label: t('jobDetail.information.totalOrderQty'),
              value: totalOrderQty,
            },
            {
              label: t('jobDetail.information.totalNoOfCartons'),
              value: totalCartonsNo,
            },
            {
              label: t('jobDetail.information.sampleSize'),
              value: item.sampleSize,
            },
            {
              label: t('jobDetail.information.aql'),
              value: [
                item.aql?.critical,
                item.aql?.major,
                item.aql?.minor,
              ].join(' / '),
            },
            {
              label: t('jobDetail.information.signedSampleAvailable'),
              value: item.isSignSample
                ? t('text.yes', { ns: 'common' })
                : t('text.no', { ns: 'common' }),
            },
            {
              label: JOB_INFO_SUB_KEY,
              value: subPO,
            },
          ];
        })}
      />
      {!!rejectReason && (
        <Section
          icon={<DraftDocumentSvg />}
          title={t('jobDetail.information.rejectReason')}
          items={[
            {
              label: '',
              value: rejectReason,
            },
          ]}
        />
      )}
    </div>
  );
};

export default SubInfo;
