import cn from 'classnames';
import toast from 'src/helpers/toast';
import classes from './CopyText.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  text: string;
  tooltipText: string;
  onClick?: () => void;
}

export const copyTextFn = (text, tooltipText) => {
  navigator.clipboard.writeText(text);
  toast.success({
    content: tooltipText,
    className: classes.toast,
    duration: 1,
    rtl: true,
    prefixCls: 'i-copy-message ant-message',
  });
};
const CopyText = ({
  className,
  children,
  text,
  tooltipText,
  onClick,
}: Props) => {
  return (
    <span
      className={cn(classes.wrapper, className)}
      onClick={
        onClick
          ? onClick
          : () => {
              copyTextFn(text, tooltipText);
            }
      }
    >
      {children}
    </span>
  );
};

export default CopyText;
