// import { FIELD_TYPES } from 'src/helpers/constants';
import Section from 'src/pages/JobRequests/JobDetail/SubContent/SubInfo/Section';

export const FORM_SETTINGS = [
  {
    title: 'Quantity Available',
    id: 'step1',
    fields: [
      {
        name: 'producedQty',
        placeholder: 'Qty',
        title: 'Produced Qty.',
        type: 'text',
        required: true,
        sub: (
          <>
            <span>Produced</span>
            <span>94.91%</span>
          </>
        ),
        limit: '5900',
      },
      {
        name: 'packedQty',
        placeholder: 'Qty',
        title: 'Packed Qty.',
        type: 'text',
        required: true,
        sub: (
          <>
            <span>Packed</span>
            <span>3.38%</span>
          </>
        ),
        subColor: 'red',
        limit: '5900',
      },
      {
        name: 'quantityResult',
        title: 'Section Result',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'passed',
            label: 'Passed',
          },
          {
            value: 'failed',
            label: 'Failed',
          },
          {
            value: 'pending',
            label: 'Pending',
          },
        ],
      },
      {
        name: 'quantityRemarks',
        placeholder: 'Remarks',
        title: 'Section Remarks',
        type: 'textarea',
      },
      {
        name: 'quantityMedia',
        placeholder: 'Media',
        title: 'Reference Media',
        type: 'media',
        required: true,
        des: (
          <>
            <span>
              Upload <span>at least one</span> media for record. Each file
              should be smaller than 5MB.
            </span>
            <span>Media format: jpg, png, mp4, mov.</span>
          </>
        ),
      },
    ],
  },
  {
    title: 'Product Description, Style & Color',
    id: 'step2',
    fields: [
      {
        name: 'signedSample',
        title: 'Signed Sample Available?',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'yes',
            label: 'Yes',
          },
          {
            value: 'no',
            label: 'No',
          },
        ],
      },
      {
        name: 'appearance',
        title: 'Appearance',
        type: 'radio',
        options: [
          {
            value: 'conformed',
            label: 'Conformed',
          },
          {
            value: 'notConform',
            label: 'Not Conform',
          },
        ],
      },
      {
        name: 'accessories',
        title: 'Accessories',
        type: 'radio',
        options: [
          {
            value: 'conformed',
            label: 'Conformed',
          },
          {
            value: 'notConform',
            label: 'Not Conform',
          },
        ],
      },
      {
        name: 'material',
        title: 'Material',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'conformed',
            label: 'Conformed',
          },
          {
            value: 'notConform',
            label: 'Not Conform',
          },
        ],
      },
      {
        name: 'package',
        title: 'Package',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'conformed',
            label: 'Conformed',
          },
          {
            value: 'notConform',
            label: 'Not Conform',
          },
        ],
      },
      {
        name: 'labelling',
        title: 'Labelling',
        type: 'radio',
        options: [
          {
            value: 'conformed',
            label: 'Conformed',
          },
          {
            value: 'notConform',
            label: 'Not Conform',
          },
        ],
      },
      {
        name: 'productResult',
        title: 'Section Result',
        type: 'radio',
        required: true,
        options: [
          {
            value: 'passed',
            label: 'Passed',
          },
          {
            value: 'failed',
            label: 'Failed',
          },
          {
            value: 'pending',
            label: 'Pending',
          },
        ],
      },
      {
        name: 'productRemarks',
        placeholder: 'Remarks',
        title: 'Section Remarks',
        type: 'textarea',
      },
      {
        name: 'productMedia',
        placeholder: 'Media',
        title: 'Reference Media',
        type: 'media',
        required: true,
        des: (
          <>
            <span>Each file should be smaller than 5MB.</span>
            <span>Media format: jpg, png, mp4, mov.</span>
          </>
        ),
      },
    ],
  },
  {
    title: 'Workmanship',
    id: 'step3',
    fields: [
      {
        editable: false,
        content: (
          <Section
            boxColor="#F0F0F0"
            box
            title="Sampling & Acceptance Limits"
            items={[
              {
                label: 'Sample Size',
                value: 'G-32',
              },
              {
                label: 'Critical',
                value: '0',
              },
              {
                label: 'Major',
                value: '2',
              },
              {
                label: 'Minor',
                value: '3',
              },
            ]}
          />
        ),
      },
      {
        name: 'workmanship1',
        title: 'Workmanship1',
        type: 'add',
        tag: 'Critical',
      },
      {
        name: 'workmanship2',
        title: 'Workmanship2',
        type: 'add',
        tag: 'Major',
      },
      {
        name: 'workmanship3',
        title: 'Workmanship3',
        type: 'add',
        tag: 'Minor',
      },
      {
        name: 'workmanship4',
        title: 'Workmanship4',
        type: 'add',
        tag: 'Minor',
      },
      {
        name: 'workmanship5',
        title: 'Workmanship5',
        type: 'add',
        tag: 'Minor',
      },
      {
        name: 'workmanship6',
        title: 'Workmanship6',
        type: 'add',
        tag: 'Minor',
      },
      {
        name: 'workmanshipRemarks',
        placeholder: 'Remarks',
        title: 'Section Remarks',
        type: 'textarea',
      },
    ],
  },
  {
    id: 'step4',
    title: 'Packing & Marking',
    fields: [
      {
        name: 'sellingUnit',
        title: 'Selling Unit',
        type: 'add1',
        required: true,
      },
      {
        name: 'innerPack',
        title: 'Inner Pack',
        type: 'add1',
        required: true,
      },
      {
        name: 'outerCarton',
        title: 'Outer Carton',
        type: 'add1',
      },
    ],
  },
  {
    id: 'step5',
    title: 'On-site Test',
    fields: [
      {
        name: 'barcodeScanning',
        title: 'Barcode Scanning',
        type: 'actionCheck',
        required: true,
      },
      {
        name: 'caronDropTest',
        title: 'Caron Drop Test',
        type: 'actionCheck',
        required: true,
      },
      {
        name: 'qrScanCheck',
        title: 'QR Scan Check',
        type: 'actionCheck',
        required: true,
      },
      {
        name: 'functionalCheck',
        title: 'Functional Check',
        type: 'actionCheck',
        required: true,
      },
    ],
  },
  {
    id: 'step6',
    title: 'Important Remarks',
    fields: [
      {
        name: 'importantRemarks',
        placeholder: 'Remarks',
        title: 'Important Remarks',
        type: 'textarea',
        required: true,
      },
      {
        name: 'attachments',
        placeholder: 'Attachments',
        title: 'Attachments',
        type: 'attachment',
        required: true,
        des: (
          <>
            <span>
              Upload at least one media for record. Each file should be smaller
              than 5MB.
            </span>
            <span>Support format: jpg, jpeg, png, doc, xlsl </span>
          </>
        ),
      },
    ],
  },
];
export const VIEW_STEP_1 = {
  step: 1,
  fields: 4,
  completedFields: 0,
};
export const VIEW_STEP_2 = {
  step: 2,
  fields: 1,
  completedFields: 0,
};
export const VIEW_STEP_5 = {
  step: 5,
  fields: 4,
  completedFields: 0,
};
export const VIEW_STEP_6 = {
  step: 6,
  fields: 2,
  completedFields: 0,
};
export const DEFAULT_VIEW_STEPS = [
  VIEW_STEP_1,
  VIEW_STEP_2,
  {
    step: 3,
    fields: 6,
    completedFields: 0,
  },
  {
    step: 4,
    fields: 2,
    completedFields: 0,
  },
];
export const DEFAULT_STEP_ACTIVE = 1;
export const TOTAL_STEPS = 6;
