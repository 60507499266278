import moment from 'moment';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from 'src/components/Page';
import mockMessages from 'src/mocks/messages';
import classes from './Chat.module.scss';
import InputBox from './components/InputBox';
import Messages from './components/Messages';
import { useActions, useIndexData } from './selectorData';

const Chat = () => {
  const { t } = useTranslation(['chat']);
  const navigate = useNavigate();
  const { messages } = useIndexData();
  const { syncChat } = useActions();

  useEffect(() => {
    syncChat(mockMessages, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.wrapper}>
      <PageHeader
        insidePageInner
        sticky
        onBack={() => {
          navigate(-1);
        }}
        title={t('title')}
        hideChatIcon
      />
      <div className={classes.content}>
        <Messages messages={messages} />
      </div>
      <div className={classes.inputBox}>
        <InputBox
          onSubmit={(val, fileList, cb) => {
            const newMessages = [
              {
                isMe: true,
                text: val,
                date: moment(),
                attachments: fileList,
              },
              {
                isMe: false,
                text: 'Hi, i got your message, will answer you soon!',
                date: moment().add(1, 'minutes'),
              },
            ];
            syncChat(newMessages);
            cb();
          }}
        />
      </div>
    </div>
  );
};

export default Chat;
