import { FlagFilled, SearchOutlined } from '@ant-design/icons';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SmileSvg } from 'src/assets/icons/Smile.svg';
import { Button, Form, FormProvider, Spin, useForm } from 'src/components';
import { SECTION_3_NAME } from 'src/helpers/constants';
import { useJobDetailStatus, useStepScroll } from 'src/helpers/hooks';
import ActionModal from 'src/pages/JobRequests/JobDetail/components/CheckIn/ActionModal';
import { translateFreeText } from 'src/utils/languagues';
import { getAllUrlParams } from 'src/utils/route';
import FieldElements from './FieldElements';
import classes from './SubInspection.module.scss';
import DefectSearchModal from './components/DefectSearchModal';
import type { StepObjType } from './components/StepsBar';
import StepsBar, { onStepClick } from './components/StepsBar';
import { getFilteredViewSteps } from './components/StepsBar/utils';
import { DEFAULT_STEP_ACTIVE } from './constants';
import { getInitialValues } from './utils';

interface Props {
  className?: string;
  formSettings: any[];
  inspectionFieldsGroup: any[];
  onForm: any;
  setIsSavedDraft: any;
  thisInspectionData: any;
  nextInspection: any;
  onNext: any;
  jobId: number | string;
  inspectionId: number | string;
  reloadInspectionDocuments: any;
}

const Title = ({ children = null, className = '', ...other }) => {
  return (
    <h1 {...other} className={cn(classes.title, className)}>
      {children}
    </h1>
  );
};
const StepWrapper = ({ children, hide = false }) => {
  return (
    <div
      className={cn(classes.stepWrapper, {
        hide,
      })}
    >
      {children}
    </div>
  );
};

const SubInspection = ({
  className,
  formSettings,
  // form,
  setIsSavedDraft,
  inspectionFieldsGroup,
  thisInspectionData,
  nextInspection,
  onNext,
  jobId,
  inspectionId,
  reloadInspectionDocuments,
  onForm,
}: Props) => {
  const hideBackToReport = true;
  const [form] = useForm();
  const { t } = useTranslation(['jobRequests', 'common']);
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = getAllUrlParams(location.search) as any;
  const { canBackToReport } = useJobDetailStatus();
  const hasNext = nextInspection && nextInspection.id;
  const [isStepClick, setIsStepClick] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [viewFirst, setViewFirst] = useState(true);
  const [viewLast, setViewLast] = useState(false);
  const [defectSearchModal, setDefectSearchModal] = useState(false);
  const [step, setStep] = useState({
    active: DEFAULT_STEP_ACTIVE,
  } as StepObjType);
  useStepScroll(step, setStep, isStepClick);
  const [loading, setLoading] = useState(false);
  const [isWarning, setIsWarning] = useState(false);
  const handleAction = (name) => (tAction) => {
    form.setFieldValue(name, tAction);
  };
  const actionModalProps = {
    isCheck: false,
    setIsCheck: (val) => {
      if (val) {
        const values = form.getFieldsValue();
        const newAllSection3Fields = Object.keys(values).filter((key) =>
          key.includes('workmanship_step_defects_')
        );
        if (newAllSection3Fields.length > 0) {
          const newObjs = {};
          newAllSection3Fields.forEach((key) => {
            newObjs[key] = {
              quantity: '0',
              classification: null,
              description: '',
              referenceMedia: [],
            };
          });
          form.setFieldsValue(newObjs);
        }
        setIsSavedDraft(false);
      }
    },
    isConfirm: isWarning,
    setIsConfirm: (val) => {
      setIsWarning(val);
    },
    onSubmit: () => {},
    icon: <SmileSvg />,
    text: (
      <span className={classes.warningContent}>
        Are you sure you want to mark all defects as “Quantity : 0” ?
      </span>
    ),
    backButtonText: t('buttons.cancel', { ns: 'common' }),
    submitButtonText: t('buttons.confirm', { ns: 'common' }),
  };
  const defectSearchModalProps = {
    opened: defectSearchModal,
    onClose: () => {
      setDefectSearchModal(false);
    },
    formSettings,
    form,
    thisInspectionData,
    setIsSavedDraft,
  };

  useEffect(() => {
    if (urlParams.step) {
      // scroll to step from preview page
      const intStep = parseInt(urlParams.step, 10);
      setScrollLoading(true);
      const filteredViewSteps = getFilteredViewSteps(formSettings);
      if (
        (filteredViewSteps.length === 6 && intStep >= 5) ||
        (filteredViewSteps.length === 5 &&
          filteredViewSteps[filteredViewSteps.length - 1].stepIndex === intStep)
      ) {
        setViewFirst(false);
        setViewLast(true);
      }
      setTimeout(() => {
        onStepClick(intStep);
        navigate(`/jobs/${jobId}/inspection/${inspectionId}`);
        setScrollLoading(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    reloadInspectionDocuments('', inspectionId);
    // if (form) {
    //   const tValues = form.getFieldsValue();
    //   const tInitialValues = getInitialValues(formSettings);
    //   form.setFieldsValue(tInitialValues);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionId]);
  onForm(form);

  return (
    <div className={cn(classes.wrapper, className)}>
      <Spin spinning={scrollLoading} />
      {!!formSettings && formSettings.length > 0 && (
        <FormProvider
          onFormChange={() => {
            setIsSavedDraft(false);
          }}
        >
          <Form
            form={form}
            name="inspection-detail"
            onFinish={() => {}}
            initialValues={getInitialValues(formSettings)}
          >
            <StepsBar
              stepObj={step}
              setStepObj={(tStep) => {
                setIsStepClick(true);
                setStep(tStep);
              }}
              form={form}
              inspectionFieldsGroup={inspectionFieldsGroup}
              formSettings={formSettings}
              setIsStepClick={setIsStepClick}
              viewFirst={viewFirst}
              setViewFirst={setViewFirst}
              viewLast={viewLast}
              setViewLast={setViewLast}
            />
            {formSettings.map((formEl, i) => {
              const isSection3 = formEl.name === SECTION_3_NAME;
              const fieldElementProps = {
                formEl,
                form,
                thisInspectionData,
                handleAction,
                setIsSavedDraft,
                t,
              };
              return (
                <StepWrapper key={i} hide={!formEl.enabled}>
                  <Title id={formEl.id}>
                    {i + 1}. {translateFreeText(formEl.title, t)}
                    {isSection3 && (
                      <SearchOutlined
                        className={classes.defectSearchIcon}
                        onClick={() => {
                          setDefectSearchModal(true);
                        }}
                      />
                    )}
                    {isSection3 && (
                      <Button
                        onClick={() => {
                          setIsWarning(true);
                        }}
                        type="link"
                        textWeight="lg"
                        className={classes.skipAllBtn}
                      >
                        Skip all
                      </Button>
                    )}
                  </Title>
                  <FieldElements {...fieldElementProps} />
                </StepWrapper>
              );
            })}
            <div className={classes.endMessage}>
              <FlagFilled />
              {t('jobDetail.inspection.endReport')}
            </div>
            {!hideBackToReport && (
              <>
                {(hasNext || canBackToReport) && (
                  <Button
                    type="primary"
                    fullWidth
                    size="large"
                    loading={loading}
                    onClick={() => {
                      setLoading(true);
                      onNext(form.getFieldsValue(), () => {
                        setLoading(false);
                      });
                    }}
                  >
                    {hasNext
                      ? t('jobDetail.inspection.nextItem')
                      : t('jobDetail.inspection.backToReport')}
                  </Button>
                )}
              </>
            )}
          </Form>
        </FormProvider>
      )}
      <ActionModal {...actionModalProps} />
      {defectSearchModal && <DefectSearchModal {...defectSearchModalProps} />}
    </div>
  );
};

export default SubInspection;
