import { Request } from './request';

export const apiLogin = async (email: string, password: string) => {
  return Request.call({
    url: '/v1/micro/auth/inspector/login',
    method: 'POST',
    data: { email, password },
  });
};
export const apiGetCurrentUser = async () => {
  return Request.call({
    url: '/v1/user/me',
    method: 'GET',
  });
};
