import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ArrowRightSvg } from 'src/assets/icons/ArrowRight.svg';
import {
  Collapse,
  CollapsePanel,
  CopyText,
  JobStatus,
  Progress,
} from 'src/components';
import { STATUS_VALUES } from 'src/helpers/constants';
import TypeJob from 'src/interfaces/Job';
import { getJobInfo } from 'src/utils/job';
import classes from './Information.module.scss';

interface Props {
  className?: string;
  onMore?: () => void;
  data: TypeJob;
  currentUserId: number;
  overallProgress: number;
}

const List = ({ children, className = '' }) => {
  return <ul className={cn(classes.list, className)}>{children}</ul>;
};

const Information = ({
  className,
  onMore,
  data,
  currentUserId,
  overallProgress,
}: Props) => {
  const { t } = useTranslation(['jobRequests', 'common']);
  const [activeKey, setActiveKey] = useState('');
  const {
    dateRange,
    address,
    status,
    dateRangeNumber,
    generalInspectLevel,
    aqLevel,
    typeOfService,
  } = getJobInfo(data, t, currentUserId);
  const isInProgress = data.status === STATUS_VALUES.IN_PROGRESS;
  const jobIdDateEl = (
    <>
      <li>
        <span>{t('jobDetail.information.jobId')}</span>
        <span>{data.jobNoList?.jobNo}</span>
      </li>
      <li>
        <span>{t('jobDetail.information.inspectionDate')}</span>
        <span>
          {dateRange} ({dateRangeNumber} {dateRangeNumber > 1 ? 'days' : 'day'})
        </span>
      </li>
    </>
  );
  return (
    <Collapse
      noStyle
      activeKey={activeKey}
      onClick={() => {
        setActiveKey(activeKey === '1' ? '' : '1');
      }}
      margin
      box
      className={className}
    >
      <CollapsePanel
        header={
          <div className={classes.information}>
            <div>
              <h2>{typeOfService}</h2>
              <span onClick={onMore}>
                {t('text.more', { ns: 'common' })}
                <span>
                  <ArrowRightSvg />
                </span>
              </span>
            </div>
            <List>
              <li>
                <span>{t('jobDetail.information.status')}</span>
                <span>
                  <JobStatus status={status} />
                </span>
              </li>
              {isInProgress ? (
                <>
                  <li>
                    <span>{t('jobDetail.information.overallProgress')}</span>
                    <span style={{ width: 74 }}>
                      <Progress percent={overallProgress} size="small" />
                    </span>
                  </li>
                </>
              ) : (
                jobIdDateEl
              )}
            </List>
          </div>
        }
        key="1"
      >
        <List className={classes.listContent}>
          {isInProgress ? (
            jobIdDateEl
          ) : (
            <li>
              <span>{t('jobDetail.information.overallProgress')}</span>
              <span>{overallProgress}%</span>
            </li>
          )}
          <li>
            <span>{t('jobDetail.information.generalInspectionLevel')}</span>
            <span>{generalInspectLevel}</span>
          </li>
          <li>
            <span>{t('jobDetail.information.acceptanceQualityLevel')}</span>
            <span>{aqLevel}</span>
          </li>
          <li>
            <span>{t('jobDetail.information.address')}</span>
            <CopyText
              text={address}
              tooltipText={t('jobDetail.information.addressCopied')}
            >
              {address}
            </CopyText>
          </li>
        </List>
      </CollapsePanel>
    </Collapse>
  );
};

export default Information;
