import { createTypes } from './createTypes';

export default createTypes(
  'GET_JOB_DETAIL',
  'GET_PO_DETAIL',
  'GET_JOB_DOCUMENTS',
  'REJECT_JOB',
  'ACCEPT_JOB',
  'CHECK_IN_JOB',
  'CHECK_OUT_JOB',
  'INSPECTION_SUBMIT',
  'SYNC_UPDATE_JOB_DETAIL',
  'SYNC_UPDATE_JOB_DOCUMENTS'
);
