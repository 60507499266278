import { Select as AntdSelect, SelectProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import classes from './Select.module.scss';

type Props = SelectProps & {
  className?: string;
};

const Select = ({ className, ...other }: Props) => {
  return <AntdSelect {...other} className={cn(classes.wrapper, className)} />;
};

export default Select;
