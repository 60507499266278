import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as AllJobSvg } from 'src/assets/icons/AllJob.svg';
import { ReactComponent as CalendarSvg } from 'src/assets/icons/Calendar.svg';
import { ReactComponent as NotificationSvg } from 'src/assets/icons/Notification.svg';
import { Badge } from 'src/components';
import classes from './Nav.module.scss';

const Nav = () => {
  const location = useLocation();
  return (
    <div className={classes.navWrapper}>
      <ul>
        <li>
          <Link
            to="/"
            className={cn({ [classes.active]: location?.pathname === '/' })}
          >
            <CalendarSvg />
          </Link>
        </li>
        <li>
          <Link
            to="/jobs"
            className={cn({
              [classes.active]:
                location?.pathname === '/jobs' ||
                String(location?.pathname).includes('/jobs/'),
            })}
          >
            <AllJobSvg />
          </Link>
        </li>
        {process.env.REACT_APP_NODE_ENV !== 'production' &&
          process.env.REACT_APP_NODE_ENV !== 'staging' && (
            <li>
              <Link
                to="/notifications"
                className={cn({
                  [classes.active]: location?.pathname === '/notifications',
                })}
              >
                <Badge count={88} size="small">
                  <NotificationSvg />
                </Badge>
              </Link>
            </li>
          )}
      </ul>
    </div>
  );
};

export default Nav;
