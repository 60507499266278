import ForgotPassword from 'src/pages/Auth/ForgotPassword';
import Login from 'src/pages/Auth/Login';
import ResetPassword from 'src/pages/Auth/ResetPassword';
import Chat from 'src/pages/Chat';
import Dashboard from 'src/pages/Dashboard';
// import Home from "src/pages/Home";
import JobRequests from 'src/pages/JobRequests';
import JobDetail from 'src/pages/JobRequests/JobDetail';
import Jobs from 'src/pages/JobRequests/Jobs';
import Notifications from 'src/pages/JobRequests/Notifications';
import VerifyAuth from './pages/VerifyCallback';

export interface RouteOtherSettings {
  hideHeader?: boolean;
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: JobRequests,
    layout: 'private',
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    layout: 'public',
    hideHeader: true,
    hideNav: true,
    innerColor: 'white',
  },
  {
    path: '/jobs/:id',
    name: 'JobDetail',
    component: JobDetail,
    layout: 'private',
    hideHeader: true,
    hideNav: true,
  },
  {
    path: '/jobs/:id/info',
    name: 'JobInformationDetail',
    component: JobDetail,
    layout: 'private',
    hideHeader: true,
    innerColor: 'white',
    hideNav: true,
  },
  {
    path: '/jobs/:id/document',
    name: 'JobDocumentDetail',
    component: JobDetail,
    layout: 'private',
    hideHeader: true,
    notPadding: true,
    hideNav: true,
  },
  {
    path: '/jobs/:id/remark',
    name: 'JobRemarkDetail',
    component: JobDetail,
    layout: 'private',
    hideHeader: true,
    notPadding: true,
    hideNav: true,
  },
  {
    path: '/jobs/:id/inspection/:inspectionId',
    name: 'JobInspectionDetail',
    component: JobDetail,
    layout: 'private',
    hideHeader: true,
    innerColor: 'gray1',
    hideNav: true,
  },
  {
    path: '/jobs/:id/submission-preview',
    name: 'JobDetailSubmissionPreview',
    component: JobDetail,
    layout: 'private',
    hideHeader: true,
    innerColor: 'gray1',
    hideNav: true,
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs,
    layout: 'private',
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    layout: 'private',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    layout: 'private',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'auth',
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    layout: 'auth',
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPassword,
    layout: 'auth',
  },
  {
    path: '/callback',
    name: 'VerifyCallback',
    component: VerifyAuth,
  },
];

export default routes;
