import { Tooltip as AntdTooltip, TooltipProps } from 'antd';
import cn from 'classnames';
import React from 'react';
import classes from './Tooltip.module.scss';

type Props = TooltipProps & {
  className?: string;
};

const Tooltip = ({ className, ...other }: Props) => {
  return (
    <AntdTooltip
      trigger={['hover', 'click']}
      {...other}
      className={cn(classes.wrapper, className)}
    />
  );
};

export default Tooltip;
