import { Collapse as AntdCollapse, CollapseProps } from 'antd';
import cn from 'classnames';
import classes from './Collapse.module.scss';

const CollapsePanel = AntdCollapse.Panel;

type Props = CollapseProps & {
  className?: string;
  noStyle?: boolean;
  onClick?: () => void;
  box?: boolean;
  margin?: boolean;
  revert?: boolean;
};

const Collapse = ({
  className,
  noStyle,
  onClick,
  box,
  margin,
  revert,
  ...other
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.margin]: margin,
          [classes.box]: box,
          [classes.noStyle]: noStyle,
          [classes.revert]: revert,
        },
        className
      )}
    >
      <AntdCollapse {...other} />
      <div className={classes.icon} onClick={onClick}>
        <span />
      </div>
    </div>
  );
};

export { CollapsePanel };
export default Collapse;
