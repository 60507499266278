import { Reducer } from 'redux';
import { defaultArr } from '../constants';
// import { handleData } from '../middlewares/handleData';
import Types from '../types/chat';

const initialState = {
  messages: {
    ...defaultArr,
  },
};

const ChatReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;
  const { messages, isSyncAll } = payload || {};

  switch (type) {
    case Types.SYNC_CHAT: {
      return {
        ...state,
        messages: {
          ...state.messages,
          data: isSyncAll
            ? [...(messages || [])]
            : [...(state.messages?.data || []), ...(messages || [])],
        },
      };
    }
    default:
      return state;
  }
};

export default ChatReducer;
