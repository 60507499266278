import { DeleteFilled, PlusCircleFilled } from '@ant-design/icons';
import type { UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SendChatSvg } from 'src/assets/icons/SendChat.svg';
import { Collapse, CollapsePanel, Icon } from 'src/components';
import { Input, Upload } from 'src/components/FormFields';
import { scrollChatContentToBottom } from 'src/utils/chat';
import classes from './InputBox.module.scss';

interface Props {
  className?: string;
  onSubmit: (val, fileList, cb) => void;
}

const InputBox = ({ className, onSubmit }: Props) => {
  const { t } = useTranslation(['chat']);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [activeKey, setActiveKey] = useState('');
  const isExpanded = activeKey === '1';
  const [val, setVal] = useState('');
  const hasVal = !!(val || '').trim() || fileList?.length;
  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(
      newFileList.map((o) => ({
        ...o,
        status: 'success',
      }))
    );
  };
  const handleSubmit = (tVal, tFileList) => {
    if (!!tVal || tFileList.length > 0) {
      onSubmit(tVal, tFileList, () => {
        setVal('');
        setFileList([]);
        scrollChatContentToBottom();
      });
    }
  };

  return (
    <Collapse
      noStyle
      activeKey={activeKey}
      onClick={() => {
        const newAKey = activeKey === '1' ? '' : '1';
        setActiveKey(newAKey);
      }}
      margin
      box
      className={cn(
        classes.wrapper,
        {
          [classes.expanded]: isExpanded,
        },
        className
      )}
      revert
    >
      <CollapsePanel
        header={
          <div className={classes.inner}>
            <div className={classes.chatInput}>
              <Upload
                fileList={fileList}
                onPreview={() => {}}
                onChange={handleChange}
                beforeUpload={() => {
                  return false;
                }}
                multiple
              >
                <PlusCircleFilled />
              </Upload>

              <Input
                type="textarea"
                placeholder={t('inputPlaceholder')}
                className={classes.input}
                size="large"
                value={val}
                onChange={(e) => {
                  setVal(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !isExpanded) {
                    setActiveKey('1');
                  }
                }}
              />
              <Icon
                size={36}
                onClick={() => {
                  handleSubmit(val, fileList);
                }}
                notMargin
                pointer
                disabled={!hasVal}
                className={cn(classes.sendIcon, {
                  [classes.sendActive]: hasVal,
                })}
              >
                <SendChatSvg />
              </Icon>
            </div>
            {fileList.length > 0 && (
              <div className={classes.listAttachments}>
                <ul>
                  {fileList.map((f, i) => {
                    return (
                      <li key={i}>
                        {f.name}
                        <DeleteFilled
                          onClick={() => {
                            setFileList(
                              fileList.filter((f, index) => index !== i)
                            );
                          }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        }
        key="1"
      ></CollapsePanel>
    </Collapse>
  );
};

export default InputBox;
