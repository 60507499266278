import cn from 'classnames';
import React from 'react';
import classes from './DefectSearchModal.module.scss';

const NotFound = ({ className = '' }) => {
  return (
    <div className={cn(classes.notFound, className)}>
      <h4>There is no result</h4>
      <span>Search it again.</span>
    </div>
  );
};

export default NotFound;
