import { Request } from './request';

export const apiGetJobs = async (
  filters?: Record<string, unknown>,
  key = ''
) => {
  return Request.call({
    url: `/v1/micro/inspections/my-job${key ? `/${key}` : ''}`,
    method: 'GET',
    params: filters,
  });
};
