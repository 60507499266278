import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Form, FormItem, FormProvider, useForm } from 'src/components';
import { Checkbox, Input } from 'src/components/FormFields';
import classes from './Login.module.scss';
import { useActions, useIndexData } from './selectorData';

const Login = () => {
  const { i18n, t } = useTranslation(['auth', 'common']);
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [form] = useForm();
  const { login, syncUpdateLanguage } = useActions();
  const { loginLoading, isLoggedIn } = useIndexData();

  if (isLoggedIn) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={classes.wrapper}>
      <h1>{t('login.title')}</h1>
      <FormProvider
        onFormChange={() => {
          setIsError(false);
        }}
      >
        <Form
          form={form}
          name="login-form"
          onFinish={(values) => {
            if (values.acceptTerms) {
              login(values.email, values.password, (res) => {
                setIsError(!res);
                form.setFields([
                  {
                    name: 'email',
                    errors: [' '],
                  },
                  {
                    name: 'password',
                    errors: [' '],
                  },
                ]);
              });
            }
          }}
          initialValues={{
            email: '',
            password: '',
            acceptTerms: true,
          }}
        >
          {isError && <div className={classes.error}>{t('login.error')}</div>}
          <FormItem
            name="email"
            label={t('form.field.email.label', { ns: 'common' })}
            rules={[
              {
                required: true,
                message: t('form.validation.email', { ns: 'common' }),
              },
              {
                type: 'email',
                message: t('form.validation.invalidEmail', {
                  ns: 'common',
                }),
              },
            ]}
          >
            <Input
              name="email"
              placeholder={t('form.field.email.label', { ns: 'common' })}
              type="text"
              size="large"
            />
          </FormItem>
          <FormItem
            name="password"
            label={t('form.field.password.label', { ns: 'common' })}
            rules={[
              {
                required: true,
                message: t('form.validation.password', { ns: 'common' }),
              },
            ]}
          >
            <Input
              name="password"
              placeholder={t('form.field.password.label', { ns: 'common' })}
              type="password"
              size="large"
            />
          </FormItem>
          <div className={classes.forgotPassword}>
            <span
              onClick={() => {
                navigate('/forgot-password');
              }}
            >
              {t('login.forgetPassword')}
            </span>
          </div>
          <div className={classes.acceptTerms}>
            <FormItem
              name="acceptTerms"
              valuePropName="checked"
              className={classes.checkbox}
            >
              <Checkbox name="acceptTerms" />
            </FormItem>
            <span>
              {t('login.termCondition.1')}{' '}
              <a
                className="jss519"
                target="_blank"
                href="https://icw.io/terms-conditions/"
                rel="noreferrer"
              >
                {t('login.termCondition.2')}
              </a>
              ,{' '}
              <a
                className="jss519"
                target="_blank"
                href="https://icw.io/privacy-policy/"
                rel="noreferrer"
              >
                {t('login.termCondition.3')}
              </a>
              , {t('login.termCondition.4')}{' '}
              <a
                className="jss519"
                target="_blank"
                href="https://icw.io/cookie-policy/"
                rel="noreferrer"
              >
                {t('login.termCondition.5')}
              </a>
              .
            </span>
          </div>
          <div className={classes.buttons}>
            <Button
              fullWidth
              type="primary"
              size="large"
              htmlType="submit"
              loading={loginLoading}
            >
              {t('buttons.login', { ns: 'common' })}
            </Button>
          </div>
          <div className={classes.switchLanguage}>
            <div>
              <span
                onClick={() => {
                  i18n.changeLanguage('en');
                  syncUpdateLanguage('en');
                }}
              >
                English
              </span>
              <span
                onClick={() => {
                  i18n.changeLanguage('cn');
                  syncUpdateLanguage('cn');
                }}
              >
                简体中文
              </span>
            </div>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default Login;
