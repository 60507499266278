import cn from 'classnames';
import { ReactComponent as ArrowLeftSvg } from 'src/assets/icons/ArrowLeft.svg';
import { ReactComponent as ArrowRight1Svg } from 'src/assets/icons/ArrowRight1.svg';
import { FormItem, Icon } from 'src/components';
import classes from './StepsBar.module.scss';
import { getFilteredViewSteps, getRequiredFieldOfStep } from './utils';

export type StepType = {
  step: number;
  fields: number;
  completedFields: number;
};
export type StepObjType = {
  active: number;
  views: StepType[];
};
interface Props {
  className?: string;
  stepObj: StepObjType;
  setStepObj: (val: StepObjType) => void;
  form: any;
  inspectionFieldsGroup: Record<
    string,
    string | number | Record<string, unknown>
  >[];
  formSettings: Record<string, string | number | Record<string, unknown>>[];
  setIsStepClick: (val: boolean) => void;
  viewFirst: boolean;
  setViewFirst: (val: boolean) => void;
  viewLast: boolean;
  setViewLast: (val: boolean) => void;
}

// offset to make sure scroll to correct title of section
const MINUS_OFFSET = 96;

export const onStepClick = (stepIndex, cb?: any) => {
  setTimeout(() => {
    const parentEl = document.querySelector(`#i-main-layout-wrapper`);
    const subEl2 = document.getElementById(`step${stepIndex}`);
    if (parentEl && subEl2) {
      parentEl.scroll({
        top: subEl2.offsetTop - MINUS_OFFSET,
        behavior: 'smooth',
      });
    }
  }, 0);
  if (cb) cb();
};

const Step = ({
  completed = false,
  active = false,
  onClick = null,
  children,
  hide = false,
}) => {
  return (
    <li
      className={cn({
        [classes.completed]: completed,
        [classes.active]: active,
        hide,
      })}
      onClick={onClick}
    >
      {children}
    </li>
  );
};
const StepsBar = ({
  className,
  stepObj,
  setStepObj,
  form,
  formSettings,
  setIsStepClick,
  viewFirst,
  setViewFirst,
  viewLast,
  setViewLast,
}: Props) => {
  const activeStep = stepObj?.active;
  const filteredViewSteps = getFilteredViewSteps(formSettings);
  const handleStepObjChange = (key, val) => {
    setStepObj({
      ...stepObj,
      [key]: val,
    });
  };

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isViewLastStep]: viewLast,
        },
        className
      )}
    >
      <FormItem shouldUpdate className={classes.inner}>
        {() => {
          const values = form.getFieldsValue();

          return (
            <>
              {viewLast && filteredViewSteps.length > 4 && (
                <Icon
                  size={24}
                  notMargin
                  pointer
                  onClick={() => {
                    setViewFirst(true);
                    setViewLast(false);
                  }}
                  className={cn(classes.arrow, classes.left)}
                >
                  <ArrowLeftSvg />
                </Icon>
              )}
              <ul>
                {filteredViewSteps.map((vStep, index) => {
                  const fieldObj = getRequiredFieldOfStep(
                    vStep.stepIndex,
                    values,
                    formSettings
                  );
                  return (
                    <Step
                      key={index}
                      completed={fieldObj.completedFields === fieldObj.fields}
                      active={activeStep === vStep.stepIndex}
                      onClick={() => {
                        handleStepObjChange('active', vStep.stepIndex);
                        onStepClick(vStep.stepIndex, () => {
                          setTimeout(() => {
                            setIsStepClick(false);
                          }, 1000);
                        });
                      }}
                      hide={
                        !fieldObj.show || (viewFirst ? index > 3 : index <= 3)
                      }
                    >
                      <span>{vStep.stepIndex}</span>
                      <span>
                        {fieldObj.completedFields}/{fieldObj?.fields}
                      </span>
                    </Step>
                  );
                })}
              </ul>
              {viewFirst && filteredViewSteps.length > 4 && (
                <Icon
                  size={24}
                  notMargin
                  pointer
                  onClick={() => {
                    setViewFirst(false);
                    setViewLast(true);
                  }}
                  className={cn(classes.arrow, classes.right)}
                >
                  <ArrowRight1Svg />
                </Icon>
              )}
            </>
          );
        }}
      </FormItem>
    </div>
  );
};
export default StepsBar;
