import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import classes from './EmptyJobs.module.scss';

const EmptyJobs = () => {
  const { t } = useTranslation('common');
  return (
    <div className={cn(classes.wrapper)}>
      <span>{t('empty.jobs')}</span>
    </div>
  );
};

export default EmptyJobs;
