import cn from 'classnames';
import React from 'react';
import classes from './Field.module.scss';

interface Props {
  className?: string;
  label: string | React.ReactNode;
  value?: string | React.ReactNode;
}

const Field = ({ className, label, value }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {!!label && <span>{label}</span>}
      {!!value && <span>{value}</span>}
    </div>
  );
};

export default Field;
