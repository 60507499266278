import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import { Spin } from 'src/components';
import AuthLayout from 'src/layout/Auth';
import MainLayout from 'src/layout/Main';
import { getCurrentUser } from 'src/store/actions/auth';
import { syncJobFilters, syncSetSelectedDate } from 'src/store/actions/job';
import {
  syncUpdateJobDetail,
  syncUpdateJobDocuments,
} from 'src/store/actions/jobDetail';
import { useAppDispatch, useReducerData } from 'src/store/hooks';
import { getMomentDate } from 'src/utils/date';
import classes from './App.module.scss';
import RoutesList, { RouteOtherSettings } from './routes';

let calledMe = 0;

const Loader = () => {
  return <Spin />;
};

const App = () => {
  const { i18n } = useTranslation();
  const [callMeLoading, setCallMeLoading] = useState(false);
  const dispatch = useAppDispatch();
  const today = getMomentDate();
  const isLoggedIn = useReducerData('auth', 'login.data.token', '');
  const language = useReducerData('shared', 'language', 'en');
  const renderRoutes = () => {
    const renderRoute = (
      Component: React.FC,
      layout: string,
      otherSettings?: RouteOtherSettings
    ) => {
      if (Component) {
        switch (layout) {
          case 'private': {
            if (isLoggedIn) {
              return (
                <MainLayout {...otherSettings}>
                  <Component />
                </MainLayout>
              );
            }
            return <Navigate to="/login" replace />;
          }
          case 'auth': {
            return (
              <AuthLayout>
                <Component />
              </AuthLayout>
            );
          }
          default:
            return (
              <MainLayout {...otherSettings}>
                <Component />
              </MainLayout>
            );
        }
      }
      return null;
    };

    return RoutesList.map(({ name, path, component, layout, ...rest }) => (
      <Route
        key={name}
        path={path}
        element={renderRoute(component, layout, rest)}
      />
    ));
  };
  const renderUnknownRoute = () => {
    return <Navigate to="/login" replace />;
  };

  useEffect(() => {
    dispatch(syncSetSelectedDate(today));
    dispatch(syncJobFilters({}));
    dispatch(syncUpdateJobDetail({}, true));
    dispatch(syncUpdateJobDocuments([]));
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isLoggedIn) {
      calledMe = 0;
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);
  if (isLoggedIn && calledMe === 0 && !callMeLoading) {
    setCallMeLoading(true);
    dispatch(
      getCurrentUser(() => {
        calledMe = 1;
        setCallMeLoading(false);
      })
    );
  }

  if (callMeLoading) {
    return <Loader />;
  }

  return (
    <div className={classes.app}>
      <Routes>
        {renderRoutes()}
        <Route path="*" element={renderUnknownRoute()} />
      </Routes>
    </div>
  );
};

export default App;
