import { Modal as AntdModal, ModalProps } from 'antd';
import cn from 'classnames';
import classes from './Modal.module.scss';

type Props = ModalProps & {
  className?: string;
  warning?: boolean;
  onClose: () => void;
  bottom?: boolean;
};
const Modal = ({ className, warning, onClose, bottom, ...other }: Props) => {
  const addProps = {} as any;
  if (warning || bottom) {
    addProps.footer = null;
    if (warning) {
      addProps.closable = false;
      addProps.centered = true;
    }
  }
  return (
    <AntdModal
      {...other}
      {...addProps}
      onCancel={onClose}
      className={cn(
        classes.wrapper,
        {
          [classes.warning]: warning,
          [classes.bottom]: bottom,
        },
        className
      )}
    />
  );
};

export default Modal;
