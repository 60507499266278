import { Request } from './request';

export const apiGetJobDetail = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}`,
    method: 'GET',
  });
};
export const apiGetPODetail = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/po-items/${id}`,
    method: 'GET',
  });
};
export const apiGetJobDocuments = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/documents`,
    method: 'GET',
    params: {
      pageNo: 1,
      pageSize: 9999,
    },
  });
};
export const apiSaveInspection = async (
  data: Record<string, unknown>,
  key = ''
) => {
  return Request.call({
    url: `/v1/micro/inspections${key ? `/${key}` : ''}`,
    method: 'POST',
    data,
  });
};
export const apiRejectJob = async (id: string | number, reason: string) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/reject`,
    method: 'POST',
    data: {
      rejectReason: reason,
    },
  });
};
export const apiAcceptJob = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/accept`,
    method: 'POST',
    data: {},
  });
};
export const apiCheckInJob = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/check-in`,
    method: 'POST',
    data: {},
  });
};
export const apiCheckOutJob = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/check-out`,
    method: 'POST',
    data: {},
  });
};
export const apiInspectionSubmit = async (id: string | number, data) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/submit`,
    method: 'POST',
    data,
  });
};
export const apiDownloadAllDocuments = async (id: string | number) => {
  return Request.call({
    url: `/v1/micro/inspections/${id}/download-documents`,
    method: 'GET',
    responseType: 'blob',
  });
};
