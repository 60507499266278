import { Dispatch } from 'redux';
import { apiGetJobs } from 'src/api';

import Types from '../types/job';

export const syncSetSelectedDate = (val) => (dispatch: Dispatch) => {
  dispatch({
    payload: val ? val.clone() : '',
    type: Types.SYNC_SET_SELECTED_DATE,
  });
};
export const syncJobFilters = (data) => (dispatch: Dispatch) => {
  dispatch({
    payload: data,
    type: Types.SYNC_JOB_FILTERS,
  });
};
export const getJobs =
  (filters?: Record<string, unknown>, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_JOBS,
      asyncCall: () => {
        return apiGetJobs(filters);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getUpcomingJobs =
  (filters?: Record<string, unknown>, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_UPCOMING_JOBS,
      asyncCall: () => {
        return apiGetJobs(filters, 'upcoming');
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getInprogressJobs =
  (filters?: Record<string, unknown>, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_INPROGRESS_JOBS,
      asyncCall: () => {
        return apiGetJobs(filters, 'in-progress');
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getEndedJobs =
  (filters?: Record<string, unknown>, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_ENDED_JOBS,
      asyncCall: () => {
        return apiGetJobs(filters, 'ended');
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getDateHasJobs =
  (filters?: Record<string, unknown>, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_DATES_HAS_JOBS,
      asyncCall: () => {
        return apiGetJobs(filters, 'date-has-job');
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
