import * as jobActions from 'src/store/actions/job';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    selectedDateStore: useReducerData('job', 'selectedDate', null),
    allJobsData: useReducerData('job', 'jobs.data', {}),
    datesHasJobs: useReducerData('job', 'datesHasJobs.data', []),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...jobActions,
  });
};
