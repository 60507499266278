/* eslint-disable @typescript-eslint/no-unused-vars */
import { CheckOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowLeftSvg } from 'src/assets/icons/ArrowLeft.svg';
import { ReactComponent as ChatSvg } from 'src/assets/icons/Chat.svg';
import { Badge, Button, Spin } from 'src/components';
import classes from './PageHeader.module.scss';

interface Props {
  className?: string;
  insidePageInner?: boolean;
  hideChatIcon?: boolean;
  sticky?: boolean;
  onBack?: () => void;
  title: string;
  saveBtn?: Record<string, unknown>;
  onSave?: () => void;
  saveLoading?: boolean;
  saved?: boolean;
  isCompleted?: boolean;
  itemNo?: string;
}

const PageHeader = ({
  className,
  insidePageInner,
  sticky,
  onBack,
  title,
  hideChatIcon,
  onSave,
  saveLoading,
  saved,
  isCompleted,
  itemNo,
}: Props) => {
  const { t } = useTranslation('common');
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.insidePageInner]: insidePageInner,
          [classes.sticky]: sticky,
        },
        className
      )}
    >
      <div className={classes.left}>
        <span className={classes.back} onClick={onBack}>
          <ArrowLeftSvg />
        </span>
        <span className={classes.title}>
          <span>{title}</span>
          {!!itemNo && <span>{itemNo}</span>}
        </span>
      </div>
      {!!onSave && (
        <>
          {saveLoading || saved ? (
            <span className={classes.saveStatus}>
              {saved ? (
                <>
                  <CheckOutlined />
                  {t('saveDraft.saved')}
                </>
              ) : (
                <>
                  <Spin circle color="#8991A0" />
                  {t('saveDraft.saving')}
                </>
              )}
            </span>
          ) : (
            <Button
              onClick={() => {
                if (!saveLoading && !!onSave && !isCompleted) {
                  onSave();
                }
              }}
              type="primary"
              size="small"
              ghost
              disabled={isCompleted}
            >
              {t('saveDraft.save')}
            </Button>
          )}
        </>
      )}
      {/* {!hideChatIcon && (
        <Link to="/chat" className={classes.chatLink}>
          <Badge count={2} size="small">
            <ChatSvg />
          </Badge>
        </Link>
      )} */}
    </div>
  );
};

export default PageHeader;
